/** ***************************************************************************
 * Organisms / Tools overlay
 *************************************************************************** */

.hns-c-tools {
  --hns-c-tools--FontSize: var(--hns-global--FontSize-paragraph--md);

  --hns-c-tools--tn--PaddingBlockStart: 1.5rem; /* 23px */
  --hns-c-tools--md--PaddingBlockStart: 3.25rem; /* 51px */
  --hns-c-tools--lg--PaddingBlockStart: 8rem; /* 129px */
  --hns-c-tools--hg--PaddingBlockStart: 9.5rem; /* 152px */
  --hns-c-tools--PaddingBlockStart: var(--hns-c-tools--tn--PaddingBlockStart);
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  .hns-c-tools {
    --hns-c-tools--PaddingBlockStart: var(--hns-c-tools--md--PaddingBlockStart);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-tools {
    --hns-c-tools--PaddingBlockStart: var(--hns-c-tools--lg--PaddingBlockStart);
  }
}

/**
 * Twice extralarge
 */

@media all and (min-width: 90rem) {
  .hns-c-tools {
    --hns-c-tools--PaddingBlockStart: var(--hns-c-tools--hg--PaddingBlockStart);
  }
}



/**
 * Component
 */

.hns-c-tools {
  display: grid;
  font-size: var(--hns-c-tools--FontSize);
  justify-items: center;
  justify-content: center;
  padding-block-start: var(--hns-c-tools--PaddingBlockStart);
}

@supports not (padding-block-start: var(--hns-c-tools--PaddingBlockStart)) {
  .hns-c-tools {
    padding-top: var(--hns-c-tools--PaddingBlockStart);
  }
}

/**
 * Large
 */

@media (min-width: 64rem) {
  .hns-c-tools {
    align-content: space-between;
    align-items: center;
    grid-gap: 15.5vh;
    grid-template-rows: auto max-content auto;
    overflow: hidden;
  }
}




/**
 * Children
 * 1: hide language chooser and social links
 */

.hns-c-tools .hns-c-language-chooser,
.hns-c-tools .hns-c-social-links {
  opacity: 0;  /* 1 */
  pointer-events: none;  /* 1 */
  position: absolute;  /* 1 */
}



/**
 * Language chooser
 * 1: show language chooser
 * 2: hide marker
 */

.hns-c-tools .hns-c-language-chooser {
  --hns-c-language-chooser__marker--Opacity: 0;  /* 2 */
  display: inline-flex;
  text-transform: lowercase;
  overflow: visible;
  transform-origin: center;
  transform: rotateZ(180deg);
  white-space: nowrap;
  writing-mode: vertical-rl;

}

.hns-c-tools .hns-c-language-chooser.hns-m-native {
  display: none;
}

.hns-c-tools .hns-c-language-chooser a:hover {
  text-decoration: none;
}

@media all and (min-height: 42rem) {
  .hns-c-tools .hns-c-language-chooser.hns-m-native {
    display: flex;
  }

  .hns-c-tools .hns-c-language-chooser.hns-m-short {
    display: none;
  }
}

/** Large / ? */
@media (min-width: 64rem) and (min-height: 23.5rem) {
  .hns-c-tools .hns-c-language-chooser {
    opacity: initial;  /* 1 */
    pointer-events: all;  /* 1 */
    position: initial;  /* 1 */
  }
}



/**
 * Social links
 * 1: show social links
 */

.hns-c-tools .hns-c-social-links {
  --hns-c-social-links--FlexDirection: column;
  --hns-c-social-links__icon--BackgroundColor: var(--hns-global--palette-yellow--100);
  --hns-c-social-links__icon--Color: currentcolor;
}

/** Large / ? */
@media all and (min-width: 64rem) and (min-height: 36.5rem) {
  .hns-c-tools .hns-c-social-links {
    opacity: initial;  /* 1 */
    pointer-events: all;  /* 1 */
    position: initial;  /* 1 */
  }
}

/** ***************************************************************************
 * Molecules / Forms / Field
 *************************************************************************** */

:root {
  --hns-c-field--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-paragraph--2xs) var(--hns-global--FontFamily-normal);;
  --hns-c-field--Gap: .5rem;  /* 8px */

  --hns-c-field--disabled--Opacity: .45;

  /**
   * Invalid indicator
   */

  --hns-c-field__before--BackgroundColor: red;
  --hns-c-field__before--InsetInlineStart: -.857em;  /* -12px */
  --hns-c-field__before--TransitionDuration: var(--hns-global--Duration--tn);

  /**
   * Checkbox
   */

  --hns-c-field__checkbox--Gap: .75rem;  /* 12px */


  /**
   * Readonly value
   */

  --hns-c-field--m-readonly__value--FontWeight: var(--hns-global--FontWeight-normal);


  /**
   * Email status
   */

  --hns-c-field--m-emailstatus__value--Gap: 1.25rem;  /* 20px */
}



/**
 * Component
 * 1: don't overflow
 * 2: contain label
 */

.hns-c-field {
  position: relative;  /* 2 */
  font: var(--hns-c-field--Font);
  display: flex;
  flex-direction: column;
  gap: var(--hns-c-field--Gap);
}

.hns-c-field.hns-m-disabled {
  opacity: var(--hns-c-field--disabled--Opacity);
}

.hns-c-field > * {
  box-sizing: border-box;  /* 1 */
  max-width: 100%;  /* 1 */
}



/**
 * Invalid indicator
 */

.hns-c-field::before {
  background-color: var(--hns-c-field__before--BackgroundColor);
  border-radius: 100vmax;
  content: "";
  display: block;
  inset-block: 0;
  inset-inline-start: var(--hns-c-field__before--InsetInlineStart);
  opacity: 0;
  position: absolute;
  transform-origin: bottom;
  transform: scaleY(0);
  transition:
    opacity var(--hns-c-field__before--TransitionDuration) linear,
    transform var(--hns-c-field__before--TransitionDuration) linear;
  width: 1px;
}

/*.hns-c-field:has(:not(:focus):invalid)::before,*/
.hns-c-field.hns-m-invalid::before {
  opacity: 1;
  transform: scaleY(1);
}

@supports not (inset-inline-start: var(--hns-c-field__before--InsetInlineStart)) {
  .hns-c-field::before {
    bottom: 0;
    left: var(--hns-c-field__before--InsetInlineStart);
    top: 0
  }
}



/**
 * Label
 * 1: put label behind widget
 * 2: extend label to the whole row
 */

.hns-c-field > label {
}

.hns-c-field > label[for] {
  cursor: pointer;
}


.hns-c-field.hns-m-disabled > label {
  cursor: inherit;
}

.hns-c-field > label::after {
  content: "";  /* 2 */
  display: block;  /* 2 */
  inset: 0;  /* 2 */
  position: absolute;  /* 2 */
  z-index: 0;  /* 1 */
}

@supports not (inset: 0) {
  .hns-c-field > label::after {
    bottom: 0;  /* 2 */
    left: 0;  /* 2 */
    right: 0;  /* 2 */
    top: 0;  /* 2 */
  }
}



/**
 * Form widget
 * 1: put widget in front of label
 */

.hns-c-field > label + * {
  z-index: 1;  /* 1 */
}



/**
 * Checkbox and radio button
 * 1: center widget vertically
 */

.hns-c-field > label:only-child {
  align-self: flex-start;
  display: flex;
  flex-direction: row-reverse;
  gap: var(--hns-c-field__checkbox--Gap);
}

.hns-c-field > label:only-child > input {
  align-self: center;  /* 1 */
}



/**
 * Readonly value
--hns-c-field--m-readonly__value--FontWeight
 */

.hns-c-field.hns-m-readonly > label + * {
  font-weight: var(--hns-c-field--m-readonly__value--FontWeight);
}



/**
 * Email status
 * 1: disabled, see Status indicator
 * 2: make room for the indicator icon
 */


.hns-c-field.hns-m-email-status {
  gap: 0;  /* 2 */
}

.hns-c-field.hns-m-email-status > label + * {
  /* gap: var(--hns-c-field--m-emailstatus__value--Gap); */  /* 1 */
  align-items: center;
  display: flex;
  flex-direction: row;
}


/**
 * Status indicator
 * 1: simulate flex gap, see Email status
 */

.hns-c-field.hns-m-email-status > label + * .hns-c-status {
  margin-inline-start: var(--hns-c-field--m-emailstatus__value--Gap);  /* 1 */
}

@supports not (margin-inline-start: var(--hns-c-field--m-emailstatus__value--Gap)) {
  .hns-c-field.hns-m-readonly > label + * .hns-c-status {
    margin-left: var(--hns-c-field--m-emailstatus__value--Gap);  /* 1 */
  }
}

/** ***************************************************************************
 * Molecules / Crags / Sector
 **************************************************************************** */

:root {
  /**
   * Header
   */

  --hns-c-sector__header--BackgroundColor: transparent;

  --hns-c-sector__header--tn--MarginBlockEnd: 2.5rem; /* 40px */
  --hns-c-sector__header--md--MarginBlockEnd: 6.5rem; /* 104px */
  --hns-c-sector__header--MarginBlockEnd: var(--hns-c-sector__header--tn--MarginBlockEnd);


  /**
   * Slider button
   */

  --hns-c-sector__slider-button--MarginBlockStart: 2.875rem;  /* 46px */


  /**
   * Floating header
   */

  --hns-c-sector__aside--tn--Column: 4 / -4;
  --hns-c-sector__aside--sm--Column: var(--column-content-start) / var(--column-content-end);
  --hns-c-sector__aside--Column: var(--hns-c-sector__aside--tn--Column);

  --hns-c-sector__aside--PaddingBlockEnd: .75rem;  /* 13px */
  --hns-c-sector__aside--PaddingBlockStart: 1rem;  /* 17px */
  --hns-c-sector__aside--TransitionDuration: var(--hns-global--Duration--xs);


  /**
   * Floating header border
   */

  --hns-c-sector__aside--after--Opacity: .36;

  --hns-c-sector__aside--after--tn--MarginInline: 0;
  --hns-c-sector__aside--after--sm--MarginInline: -1.625rem;  /* -26px */
  --hns-c-sector__aside--after--MarginInline: var(--hns-c-sector__aside--after--tn--MarginInline);


  /**
   * Floating header heading
   */

  --hns-c-sector__aside-heading--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-title--2xs) var(--hns-global--FontFamily-normal);


  /**
   * Floating header subtitle
   */

  --hns-c-sector__aside-subtitle--Font: var(--hns-global--FontWeight-regular) var(--hns-global--FontSize-paragraph--2xs) var(--hns-global--FontFamily-normal);


  /**
   * Floating header button
   */

  --hns-c-sector__aside-button--FontSize: var(--hns-global--FontSize-button--xs);
  --hns-c-sector__aside-button--PaddingBlock: .5em;   /* 8px */
  --hns-c-sector__aside-button--PaddingInline: 1.125em;  /* 17.5px */
}

/**
 * Small
 */

@media all and (min-width: 34rem) {
  :root {
    --hns-c-sector__aside--after--MarginInline: var(--hns-c-sector__aside--after--sm--MarginInline);
    --hns-c-sector__aside--Column: var(--hns-c-sector__aside--sm--Column);
  }
}

/**
 * Medium
 */

@media screen and (min-width: 48rem) {
  :root {
    --hns-c-sector__header--MarginBlockEnd: var(--hns-c-sector__header--tn--MarginBlockEnd);
  }
}


/**
 * Component
 * 1: without this header can't be sticky
 * 2: contain floating header
 */

.hns-c-sectioning.hns-c-sector {
  overflow-x: initial;  /* 1 */
  position: relative;  /* 2 */
}



/**
 * Header
 */

.hns-c-sectioning.hns-c-sector > header {
  grid:
    "subtitle"
    "heading"
    "button";
  margin-block-end: var(--hns-c-sector__header--MarginBlockEnd);
}

@supports not (margin-block-end: var(--hns-c-sector__header--MarginBlockEnd)) {
  .hns-c-sector > header {
    margin-bottom: var(--hns-c-sector__header--MarginBlockEnd);
  }
}



/**
 * Slider button
 */

.hns-c-sector > header > button {
  align-self: start;
  display: none;
  grid-area: button;
  margin-block-start: var(--hns-c-sector__slider-button--MarginBlockStart);
}

.hns-c-sector.hns-m-js > header > button {
  display: block;
}

@supports not (margin-block-start: var(--hns-c-sector__slider-button--MarginBlockStart)) {
  .hns-c-sector > header > button {
    margin-top: var(--hns-c-sector__slider-button--MarginBlockStart);
  }
}



/**
 * Rocks
 */

.hns-c-sector > header > svg {
  left: initial;
  right: 0;
  transform-origin: top right;
}


/**
 * Small rock
 */

.hns-c-sectioning.hns-c-sector > header > svg:nth-of-type(1) {
  transform: translate(20%, -25%) rotate(-20deg);
  width: 5rem; /* can't use scale: safari scales the stroke */
}


/**
 * Big rock
 */

.hns-c-sectioning.hns-c-sector > header > svg:nth-of-type(2) {
  transform: translate(220%, 30%) rotate(131deg);
}



/**
 * Floating header
 * 1: reset
 * 2: same background color as its container
 * 3: stay on top of triangles
 */

.hns-c-sector > aside {
  align-items: start;
  background-color: rgba(
    var(--hns-c-sectioning--BackgroundColor-r),
    var(--hns-c-sectioning--BackgroundColor-g),
    var(--hns-c-sectioning--BackgroundColor-b),
    var(--hns-c-sectioning--BackgroundColor-a)
  );  /* 2 */
  display: grid;
  grid-column: var(--hns-c-sector__aside--Column);
  grid:
    "subtitle button"
    "heading  button"
  / 1fr auto;
  justify-items: start;
  margin-block-start: calc(-1 * var(--hns-c-sector__header--MarginBlockEnd));
  opacity: 0;
  padding-block: var(--hns-c-sector__aside--PaddingBlockStart) var(--hns-c-sector__aside--PaddingBlockEnd);
  pointer-events: none;
  position: sticky;
  inset-block-start: 0;
  transition: opacity var(--hns-c-sector__aside--TransitionDuration) linear;
  z-index: 1;  /* 3 */
}

.hns-c-sector.gw-m-floating > aside {
  opacity: 1;
  pointer-events: all;
}

.hns-c-sector > aside > * {
  margin: 0;  /* 1 */
}

@supports not (padding-block-end: var(--hns-c-sector__aside--PaddingBlockEnd)) {
  .hns-c-sector > aside {
    padding-bottom: var(--hns-c-sector__aside--PaddingBlockEnd);
    padding-top: var(--hns-c-sector__aside--PaddingBlockStart);
    top: 0;
  }
}



/**
 * Floating header border
 * 1: clip shadow
 */

.hns-c-sector > aside::after {
  background-color: rgba(
    var(--hns-c-sectioning--Color-r),
    var(--hns-c-sectioning--Color-g),
    var(--hns-c-sectioning--Color-b),
    var(--hns-c-sector__aside--after--Opacity)
  );
  box-shadow: 0 2px 30px 12px rgb(
    var(--hns-c-sectioning--BackgroundColor-r),
    var(--hns-c-sectioning--BackgroundColor-g),
    var(--hns-c-sectioning--BackgroundColor-b),
  );
  clip-path: inset(0 0 -100vmax 0);
  content: "";
  display: block;
  height: 1px;
  inset-block-end: 0;
  inset-inline: 0;
  margin-inline: var(--hns-c-sector__aside--after--MarginInline);
  position: absolute;
}

@supports not (inset-block-end: 0) {
  .hns-c-sector > aside::after {
    bottom: 0;
    left: 0;
    right: 0;
  }
}



/**
 * Floating header content (heading and subtitle)
 * 1: hide on small viewports
 */

.hns-c-sector > aside > :first-child,
.hns-c-sector > aside > p {
  display: none;  /* 1 */
}

/**
 * Small
 */

@media all and (min-width: 34rem) {
  .hns-c-sector > aside > :first-child,
  .hns-c-sector > aside > p {
    display: block;  /* 1 */
  }
}



/**
 * Floating header heading
 */

.hns-c-sector > aside > :first-child {
  grid-area: heading;
  font: var(--hns-c-sector__aside-heading--Font);
}



/**
 * Floating header subtitle
 */

.hns-c-sector > aside > p {
  grid-area: subtitle;
  font: var(--hns-c-sector__aside-subtitle--Font);
}



/**
 * Floating header button
 * 1: setting --hns-u-button--Padding* doesn't work here
 */

.hns-c-sector > aside > button {
  --hns-u-button--FontSize: var(--hns-c-sector__aside-button--FontSize);
  /*grid-area: button;*/
  grid-row: 1 / -1;
  padding-block: var(--hns-c-sector__aside-button--PaddingBlock);  /* 1 */
  padding-inline: var(--hns-c-sector__aside-button--PaddingInline);  /* 1 */
}

@supports not (padding-block: var(--hns-c-sector__aside-button--PaddingBlock)) {
  .hns-c-sector > aside > button {
    padding: var(--hns-c-sector__aside-button--PaddingBlock) var(--hns-c-sector__aside-button--PaddingInline);
  }
}



/**
 * Old code
 */

@media all and (min-width: 90rem) { /* hg */
  .hns-c-sectioning.hns-c-sector {
    --column-content-end: -9;
  }
}

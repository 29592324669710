/** ***************************************************************************
 * Atoms / Reserved area / Button
 *************************************************************************** */

@keyframes hns-c-reserved-button--shake {
  0% {
    transform: rotateZ(0);
  }
  20%, 40%, 60%, 80% {
    transform: rotateZ(-10deg);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotateZ(10deg);
  }
}

.hns-c-reserved-button {
  --hns-c-reserved-button--AccentColor: var(--hns-global--palette-blue--100);
  --hns-c-reserved-button--Color: var(--hns-global--palette-white);
  --hns-c-reserved-button--Gap: 1em;

  --hns-c-reserved-button--tn--BackgroundColor: var(--hns-c-reserved-button--AccentColor);
  --hns-c-reserved-button--md--BackgroundColor: transparent;
  --hns-c-reserved-button--BackgroundColor: var(--hns-c-reserved-button--tn--BackgroundColor);

  --hns-c-reserved-button--tn--FontFamily: var(--hns-global--FontFamily-normal);
  --hns-c-reserved-button--md--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-reserved-button--FontFamily: var(--hns-c-reserved-button--tn--FontFamily);

  --hns-c-reserved-button--tn--FontSize: var(--hns-global--FontSize-title--2xs);
  --hns-c-reserved-button--md--FontSize: var(--hns-global--FontSize-title--xs);
  --hns-c-reserved-button--FontSize: var(--hns-c-reserved-button--tn--FontSize);

  --hns-c-reserved-button--tn--LineHeight: inherit;
  --hns-c-reserved-button--md--LineHeight: 1;
  --hns-c-reserved-button--LineHeight: var(--hns-c-reserved-button--tn--LineHeight);

  --hns-c-reserved-button--tn--TextTransform: initial;
  --hns-c-reserved-button--md--TextTransform: uppercase;
  --hns-c-reserved-button--TextTransform: var(--hns-c-reserved-button--tn--TextTransform);

  --hns-c-reserved-button--tn--PaddingBlock: .875em;  /* 14px */
  --hns-c-reserved-button--md--PaddingBlock: 0;
  --hns-c-reserved-button--PaddingBlock: var(--hns-c-reserved-button--tn--PaddingBlock);


  /**
   * Icon
   */

  --hns-c-reserved-button__icon--BlockSize: 2.125em;  /* 34px */

  --hns-c-reserved-button--hover__icon--Animation: hns-c-reserved-button--shake var(--hns-global--Duration--md) linear;


  /**
   * Greeting
   */

  --hns-c-reserved-button__greeting--FontFamily: var(--hns-c-reserved-button--tn--FontFamily);
  --hns-c-reserved-button__greeting--FontSize: var(--hns-c-reserved-button--tn--FontSize);
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  .hns-c-reserved-button {
    --hns-c-reserved-button--BackgroundColor: var(--hns-c-reserved-button--md--BackgroundColor);
    --hns-c-reserved-button--FontFamily: var(--hns-c-reserved-button--md--FontFamily);
    --hns-c-reserved-button--FontSize: var(--hns-c-reserved-button--md--FontSize);
    --hns-c-reserved-button--LineHeight: var(--hns-c-reserved-button--md--LineHeight);
    --hns-c-reserved-button--PaddingBlock: var(--hns-c-reserved-button--md--PaddingBlock);
    --hns-c-reserved-button--TextTransform: var(--hns-c-reserved-button--md--TextTransform);
  }
}


/**
 * Component
 * 1: reset
 * 2: disabled, see icon
 * 3: extend background on small viewports
 */

.hns-c-reserved-button {
  /* gap: var(--hns-c-reserved-button--Gap); */  /* 2 */
  accent-color: var(--hns-c-reserved-button--AccentColor);
  align-items: center;
  background-color: var(--hns-c-reserved-button--BackgroundColor);
  box-shadow: 0 0 0 100vmax var(--hns-c-reserved-button--BackgroundColor);  /* 3 */
  clip-path: inset(0 -100vmax);  /* 3 */
  color: var(--hns-c-reserved-button--Color);
  display: inline-flex;
  flex-direction: row;
  font-family: var(--hns-c-reserved-button--FontFamily);
  font-size: var(--hns-c-reserved-button--FontSize);
  line-height: var(--hns-c-reserved-button--LineHeight);
  padding-block: var(--hns-c-reserved-button--PaddingBlock);
  text-decoration: none;  /* 1 */
  text-transform: var(--hns-c-reserved-button--TextTransform);
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  .hns-c-reserved-button {
    box-shadow: initial;  /* 3 */
    clip-path: initial;  /* 3 */
  }
}

@supports not (padding-block: var(--hns-c-reserved-button--PaddingBlock)) {
  .hns-c-reserved-button {
    padding-bottom: var(--hns-c-reserved-button--PaddingBlock);
    padding-top: var(--hns-c-reserved-button--PaddingBlock);
  }
}



/**
 * Icon
 * 1: simulate gap on container
 */

.hns-c-reserved-button svg {
  --hns-c-icon-user__background--Fill: var(--hns-c-reserved-button--Color);
  --hns-c-icon-user__icon--Fill: var(--hns-c-reserved-button--AccentColor);
  block-size: var(--hns-c-reserved-button__icon--BlockSize);
  inline-size: auto;
  margin-inline-end: var(--hns-c-reserved-button--Gap);  /* 1 */
}

.hns-c-reserved-button:hover svg {
  animation: var(--hns-c-reserved-button--hover__icon--Animation);
}

@supports not (block-size: var(--hns-c-reserved-button__icon--BlockSize)) {
  .hns-c-reserved-button svg {
    height: var(--hns-c-reserved-button__icon--BlockSize);
    margin-right: var(--hns-c-reserved-button--Gap);  /* 1 */
    width: auto;
  }
}



/**
 * Greeting
 */

.hns-c-reserved-button span span {
  display: block;
  font-family: var(--hns-c-reserved-button__greeting--FontFamily);
  font-size: var(--hns-c-reserved-button__greeting--FontSize);
  text-transform: initial;
}

/** ***********************************************************************************
 * Organisms / Reserved area / Partner card
 *********************************************************************************** */

.hns-c-partner {
  --hns-c-partner--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-label--xs) var(--hns-global--FontFamily-normal);

  --hns-c-partner--hover--TransitionDuration: .15s;


  /**
   * Header
   */

  --hns-c-partner__header--RowGap: .2em;  /* eyeballed */
  --hns-c-partner--PaddingBlock: .78em;  /* 11px */

  /**
   * Heading
   */

  --hns-c-partner__heading--FontSize: 114%;  /* 16px */


  /**
   * Subheading
   */

  --hns-c-partner__subheading--FontWeight: var(--hns-global--FontWeight-normal);


  /**
   * Button
   */

  --hns-c-partner__button--BlockSize: 2.29em;  /* 16px */
}



/**
 * Component
 * 1: contain button
 * 2: reset
 */

.hns-c-partner {
  background-color: var(--hns-c-partner--BackgroundColor);
  color: var(--hns-c-partner--Color);
  font: var(--hns-c-partner--Font);
  position: relative;
  transition: transform var(--hns-c-partner--hover--TransitionDuration) ease;
}



/**
 * Header
 */

.hns-c-partner > header {
  display: grid;
  grid:
    ". ." var(--hns-c-partner--PaddingBlock)
    "heading button"
    "subheading button"
    ". ." var(--hns-c-partner--PaddingBlock)
  / 1fr auto;
}

.hns-c-partner > header > * {
  margin: 0;
}


/**
 * Header borders
 * 1: pill-shaped
 **/

.hns-c-partner > header::before,
.hns-c-partner > header::after {
  background-color: currentcolor;
  block-size: 1px;
  border-radius: 100vmax;  /* 1 */
  content: "";
  display: block;
  grid-column: 1 / -1;
  transition: transform var(--hns-c-partner--hover--TransitionDuration) linear;
}

.hns-c-partner:hover > header::before,
.hns-c-partner:hover > header::after {
  transform: scaleY(2);
}


.hns-c-partner > header::before {
  align-self: start;
  transform-origin: top;
}

.hns-c-partner > header::after {
  align-self: end;
  transform-origin: bottom;
}

@supports not (block-size: 1px) {
  .hns-c-partner > header::before,
  .hns-c-partner > header::after {
    height: 1px;
  }
}



/**
 * Heading
 */

.hns-c-partner > header > :first-child {
  font-size: var(--hns-c-partner__heading--FontSize);
  grid-area: heading;
}



/**
 * Subheading
 */

.hns-c-partner > header p {
  font-weight: var(--hns-c-partner__subheading--FontWeight);
  grid-area: subheading;
}



/**
 * button
 * 1: reset
 * 2: don't cut icon border
 * 3: extend active area
 */

.hns-c-partner > header button {
  align-self: center;
  appearance: none;  /* 1 */
  aspect-ratio: 1 / 1;
  background-color: transparent;  /* 1 */
  block-size: var(--hns-c-partner__button--BlockSize);
  border: 0;  /* 1 */
  color: inherit;  /* 1 */
  display: none;
  grid-area: button;
  font: inherit;  /* 1 */
  padding: 1px;  /* 2 */
  cursor: pointer;
}

.hns-c-partner:hover > header button {
  --hns-c-icon-plus__circle--Fill: currentcolor;
  --hns-c-icon-plus__plus--Fill: white;
}

.hns-c-partner.hns-m-js > header button {
  display: block;
}

.hns-c-partner > header button::before {
  content: "";
  display: block;
  inset: 0;  /* 3 */
  position: absolute;  /* 3 */
}

@supports not(inset: 0) {
  .hns-c-partner > header button::before {
    bottom: 0;  /* 3 */
    left: 0;  /* 3 */
    right: 0;  /* 3 */
    top: 0;  /* 3 */
  }
}

@supports not (block-size: var(--hns-c-partner__button--BlockSize)) {
  .hns-c-partner > header button {
    height: var(--hns-c-partner__button--BlockSize);
  }
}

@supports not (aspect-ratio: 1 / 1) {
  .hns-c-partner > header button {
    height: var(--hns-c-partner__button--BlockSize);
    width: var(--hns-c-partner__button--BlockSize);
  }
}

.hns-c-partner > p {
  margin-block-end: 0;
}
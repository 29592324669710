/* post list */

  .post-list {
    --column-count: 1;
    --gap-column: 3.25rem; /* 53px */
    --gap-row: 3.5rem; /* 56px */
    align-items: start;
    display: grid;
    grid-column-gap: var(--gap-column);
    grid-column: var(--column-post-list-start, 1) / var(--column-post-list-end, -1);
    grid-row-gap: var(--gap-row);
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .post-list > * {
    margin: 0;
    padding: 0;
  }

  .post-list > *::before {
    display: none;
  }

/** ***************************************************************************
 * Atoms / Button
 *************************************************************************** */

:root {
  --hns-c-button--m-primary--Color: var(--hns-global--palette-white);

  --hns-c-button--m-size-xs--FontSize: var(--hns-global--FontSize-button--xs);
  --hns-c-button--m-size-sm--FontSize: var(--hns-global--FontSize-button--sm);
  --hns-c-button--m-size-lg--FontSize: var(--hns-global--FontSize-button--lg);

  /**
   * Background
   */

  --hns-c-button--m-primary__background--BackgroundColor: var(--hns-global--palette-blue);
}

.hns-c-button.hns-m-primary {
  --hns-u-button--Color: var(--hns-c-button--m-primary--Color);
  --hns-u-button__background--BackgroundColor: var(--hns-c-button--m-primary__background--BackgroundColor);
  --hns-u-button__background--BorderColor: var(--hns-u-button__background--BackgroundColor);
}

.hns-c-button.hns-m-size-xs {
  --hns-u-button--FontSize: var(--hns-c-button--m-size-xs--FontSize);
}

.hns-c-button.hns-m-size-sm {
  --hns-u-button--FontSize: var(--hns-c-button--m-size-sm--FontSize);
}

.hns-c-button.hns-m-size-lg {
  --hns-u-button--FontSize: var(--hns-c-button--m-size-lg--FontSize);
}

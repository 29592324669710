/** ***************************************************************************
 * Organisms / Alert
 *************************************************************************** */

@keyframes hns-c-alert--dismiss {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    position: absolute;
    transform: scaleY(0);
    pointer-events: none;
  }
}

@keyframes hns-c-alert--destroy {
  from {
    opacity: 1;
    transform: scaleY(1);
  }
  to {
    opacity: 0;
    position: absolute;
    transform: scaleY(0);
    pointer-events: none;
  }
}

.hns-c-alert {
  --hns-c-alert--BackgroundColor: var(--hns-global--palette-blue--80);
  --hns-c-alert--BorderRadius: .375em;  /* 6px */
  --hns-c-alert--Color: var(--hns-global--palette-white);
  --hns-c-alert--Font: var(--hns-global--FontWeight-regular) var(--hns-global--FontSize-label--sm) var(--hns-global--FontFamily-normal);
  --hns-c-alert--PaddingBlock: 1.25em;  /* 21px */
  --hns-c-alert--PaddingInline: 1.125em;  /* 18px */
  --hns-c-alert--TransitionDuration: var(--hns-global--Duration--sm);

  --hns-c-alert--m-autodestruct--AnimationDelay: 7s;
  --hns-c-alert--m-autodestruct--AnimationDuration: 5s;


  /**
   * Heading
   */

  --hns-c-alert__heading--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-paragraph--2xs) var(--hns-global--FontFamily-normal);;
  --hns-c-alert__heading--MarginBlockEnd: .357em;  /* 5px */


  /**
   * Close button
   */

  --hns-c-alert__close--BlockSize: 1.5rem;
  --hns-c-alert__close--InsetBlockStart: .5rem;
  --hns-c-alert__close--InsetInlineEnd: var(--hns-c-alert__close--InsetBlockStart);
}



/**
 * Component
 * 1: contain close button
 */

.hns-c-alert {
  background-color: var(--hns-c-alert--BackgroundColor);
  border-radius: var(--hns-c-alert--BorderRadius);
  color: var(--hns-c-alert--Color);
  font: var(--hns-c-alert--Font);
  padding-block: var(--hns-c-alert--PaddingBlock);
  padding-inline: var(--hns-c-alert--PaddingInline);
  position: relative;  /* 1 */
  transform-origin: top;
}

.hns-c-toggle:checked + .hns-c-alert {
  animation: hns-c-alert--dismiss var(--hns-c-alert--TransitionDuration) linear forwards;
}

@supports not (padding-block: var(--hns-c-alert--PaddingBlock)) {
  .hns-c-alert {
    padding: var(--hns-c-alert--PaddingBlock) var(--hns-c-alert--PaddingInline);
  }
}



/**
 * Autodestruct variant
 */



.hns-c-alert.hns-m-autodestruct {
  animation: hns-c-alert--destroy var(--hns-c-alert--m-autodestruct--AnimationDuration) linear forwards;
  animation-delay: var(--hns-c-alert--m-autodestruct--AnimationDelay);
}

.hns-c-alert.hns-m-autodestruct:hover {
  animation-play-state: paused;
}

.hns-c-toggle:checked + .hns-c-alert.hns-m-autodestruct {
  animation: hns-c-alert--dismiss var(--hns-c-alert--TransitionDuration) linear forwards;
  animation-delay: 0;
}


/**
 * Childen
 * 1: reset
 */

.hns-c-alert > * {
  margin: 0;  /* 1 */
}



/**
 * Heading
 */

.hns-c-alert > :is(h1, h2, h3, h4, h5, h6) {
  font: var(--hns-c-alert__heading--Font);
  margin-block-end: var(--hns-c-alert__heading--MarginBlockEnd);
}

@supports not (margin-block-end: var(--hns-c-alert__heading--MarginBlockEnd)) {
  .hns-c-alert > :is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: var(--hns-c-alert__heading--MarginBlockEnd);
  }
}



/**
 * Close button
 */

.hns-c-alert__close {
  inset-block-start: var(--hns-c-alert__close--InsetBlockStart);
  inset-inline-end: var(--hns-c-alert__close--InsetInlineEnd);
  position: absolute;
}

.hns-c-alert__close svg {
  block-size: var(--hns-c-alert__close--BlockSize);
  transform: rotateZ(45deg);
}

@supports not (inset-block-start: var(--hns-c-alert__close--InsetBlockStart)) {
  .hns-c-alert__close {
    top: var(--hns-c-alert__close--InsetBlockStart);
    right: var(--hns-c-alert__close--InsetInlineEnd);
  }

  .hns-c-alert__close svg {
    height: var(--hns-c-alert__close--BlockSize);
  }
}

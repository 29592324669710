/** ***************************************************************************
 * Atoms / Typography / Heading
 *************************************************************************** */

:root {
  --hns-c-heading--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-heading--FontSize: 2em;
  --hns-c-heading--MarginBlock: 1em;
}



/**
 * Component
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--hns-c-heading--FontFamily);
  font-size: var(--hns-c-heading--FontSize);
  font-weight: var(--hns-global--FontWeight-heavy);
  line-height: 1;
  margin-block: var(--hns-c-heading--MarginBlock);
}

@supports not (margin-block: var(--hns-c-heading--MarginBlock)) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: var(--hns-c-heading--MarginBlock);
    margin-top: var(--hns-c-heading--MarginBlock);
  }
}

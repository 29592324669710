/** ***********************************************************************************
 * Molecules / Reserved area / Breadcrumbs
 *********************************************************************************** */

.hns-c-reserved-breadcrumbs {
  --hns-c-reserved-breadcrumbs--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-label--xs) var(--hns-global--FontFamily-normal);;

  /**
   * Items
   */

  --hns-c-reserved-breadcrumbs__items--ColumnGap: 1ch;


  /**
   * Logo
   */

  --hns-c-reserved-breadcrumbs__logo--BlockSize: 5.5rem;  /* 89px */
}



/**
 * Component
 */

.hns-c-reserved-breadcrumbs {
  font: var(--hns-c-reserved-breadcrumbs--Font);
}



/**
 * Items
 * 1: reset
 * 2: disabled, see Link and divider
 * 3: hide below medium
 */

.hns-c-reserved-breadcrumbs ul {
  --hns-c-list__item--MarginBlockStart: 0;  /* 1 */
  --hns-c-list__item--PaddingBlockStart: 0;  /* 1 */
  --hns-c-list__item--PaddingInlineStart: 0;  /* 1 */
  /* column-gap: var(--hns-c-reserved-breadcrumbs__items--ColumnGap); */  /* 2 */
  display: none;  /* 3 */
  flex-direction: row;
  list-style-type: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

/** Medium */
@media all and (min-width: 48rem) {
  .hns-c-reserved-breadcrumbs ul {
    display: flex;  /* 3 */
  }
}



/**
 * Item
 * 1: reset
 */

.hns-c-reserved-breadcrumbs li {
  display: contents;
}

.hns-c-reserved-breadcrumbs li::before {
  display: none;  /* 1 */
  position: initial;  /* 1 */
}



/**
 * Link and divider
 * 1: simulate column-gap, see Items
 */

.hns-c-reserved-breadcrumbs li:nth-of-type(n+2) a,
.hns-c-reserved-breadcrumbs li:nth-of-type(n+2)::before {
  margin-inline-start: var(--hns-c-reserved-breadcrumbs__items--ColumnGap);  /* 1 */
}

@supports not (margin-inline-start: var(--hns-c-reserved-breadcrumbs__items--ColumnGap)) {
  .hns-c-reserved-breadcrumbs li:nth-of-type(n+2) a,
  .hns-c-reserved-breadcrumbs li:nth-of-type(n+2)::before {
    margin-left: var(--hns-c-reserved-breadcrumbs__items--ColumnGap);  /* 1 */
  }
}



/**
 * Link
 * 1: reset
 */

.hns-c-reserved-breadcrumbs a {
  text-decoration: none;  /* 1 */
}



/**
 * Divider
 * 1: reset
 */

.hns-c-reserved-breadcrumbs li:nth-of-type(n+2)::before {
  content: "/";
  display: block;
  font: inherit;  /* 1 */
}



/**
 * Logo
 * 1: show only below medium 
 */

.hns-c-reserved-breadcrumbs > svg {
  block-size: var(--hns-c-reserved-breadcrumbs__logo--BlockSize);
}

@supports not (block-size: var(--hns-c-reserved-breadcrumbs__logo--BlockSize)) {
  .hns-c-reserved-breadcrumbs > svg {
    height: var(--hns-c-reserved-breadcrumbs__logo--BlockSize);
  }
}

/** Medium */
@media all and (min-width: 48rem) {
  .hns-c-reserved-breadcrumbs > svg {
    display: none;  /* 1 */
  }
}

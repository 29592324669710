/** ***************************************************************************
 * Molecules / Blog / Post item
 *************************************************************************** */

:root {
  --hns-c-post-item--Color: var(--hns-global--palette-blue--100);
  --hns-c-post-item--Width: 20rem;  /* 320px */


  /**
   * Heading (post title)
   */

  --hns-c-post-item__heading--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-post-item__heading--FontSize: var(--hns-global--FontSize-title--md);
  --hns-c-post-item__heading--FontWeight: var(--hns-global--FontWeight-heavy);
  --hns-c-post-item__heading--MarginBlockStart: 3.5rem;  /* 56px */


  /**
   * Post metadata
   */

  --hns-c-post-item__meta--Color: var(--hns-global--palette-yellow--100);

  --hns-c-post-item__meta--tn--PaddingInline: 1.75rem;  /* 27px */
  --hns-c-post-item__meta--md--PaddingInline: 0;
  --hns-c-post-item__meta--PaddingInline: var(--hns-c-post-item__meta--tn--PaddingInline);
}

/*
 * Medium
 */

@media all and (min-width: 48rem) {
  :root {
    --hns-c-post-item__meta--PaddingInline: var(--hns-c-post-item__meta--md--PaddingInline);
  }
}


/**
 * Component
 * 1: reset
 * 2: contain button
 */

.hns-c-post-item {
  align-items: stretch;
  color: var(--hns-c-post-item--Color);
  display: grid;
  justify-items: stretch;
  position: relative;  /* 2 */
  width: var(--hns-c-post-item--Width);

  --margin-title-before: 3.5rem; /* 56px */
  grid:
    "meta meta    meta" auto
    ".    .       ."    var(--hns-c-post-item__heading--MarginBlockStart)
    ".    heading ."    auto
    ".    .       ."    2rem
    ".    image   ."    auto
    ".    .       ."    2rem
    ".    button  ."
  / 3rem  1fr     3rem;

}

.hns-c-post-item:hover {
  --hns-u-button__border--Transform: var(--hns-u-button__border--hover--Transform);
}

.hns-c-post-item > * {
  margin: 0;  /* 1 */
}



/**
 * Heading (post title)
 */

.hns-c-post-item > :first-child {
  font-family: var(--hns-c-post-item__heading--FontFamily);
  font-size: var(--hns-c-post-item__heading--FontSize);
  font-weight: var(--hns-c-post-item__heading--FontWeight);
  grid-area: heading;
}


/**
 * Post metadata
 */

.hns-c-post-item dl {
  align-items: baseline;
  color: var(--hns-c-post-item__meta--Color);
  display: flex;
  flex-direction: row-reverse;
  grid-area: meta;
  justify-content: space-between;
  padding-block: 0;
  padding-inline: var(--hns-c-post-item__meta--PaddingInline);
}

@supports not (padding-inline: var(--hns-c-post-item__meta--PaddingInline)) {
  .hns-c-post-item dl {
    padding: 0 var(--hns-c-post-item__meta--PaddingInline);
  }
}


/**
 * Button
 */

.hns-c-post-item__button {
  align-self: center;
  grid-area: button;
  justify-self: center;
}



/* Old code */

  /* typography */

    /* date */
    .hns-c-post-item dd:nth-of-type(1) {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--md);
      font-weight: var(--hns-global--FontWeight-heavy);
    }

    /* category */
    .hns-c-post-item dd:nth-of-type(2) {
      font-family: var(--hns-global--FontFamily-normal);
      font-size: var(--hns-global--FontSize-label--lg);
      font-weight: var(--hns-global--FontWeight-bold);
      text-transform: capitalize;
    }

  /* colors */



    .hns-c-post-item::before {
      background-color: rgba(
        var(--hns-global--palette-yellow--60-r),
        var(--hns-global--palette-yellow--60-g),
        var(--hns-global--palette-yellow--60-b),
        1
      );
    }

  /* layout */

  .hns-c-post-item::before {
      content: "";
      display: block;
      grid-column: 1 / -1;
      grid-row: 2 / -3;
      position: relative;
      transform: translateY(.5rem);
      z-index: -1;
    }

    .hns-c-post-item.hns-m-image::before {
      grid-row: 3 / -3;
      transform: translateY(calc(-1 * var(--margin-title-before) + .5rem));
    }

    .hns-c-post-item dt {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    .hns-c-post-item dd {
      margin: 0;
    }

    .hns-c-post-item.hns-m-image > div {
      grid-area: image;
      padding-bottom: 100%; /* aspect ratio */
      position: relative;
      transition: transform var(--hns-global--Duration--sm) linear;
    }

      .hns-c-post-item.hns-m-image:hover > div {
        transform: scale(1.05);
      }

    .hns-c-post-item img {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .hns-c-post-item.hns-m-js:hover {
      cursor: pointer;
    }

/** ***************************************************************************
 * Molecules / CMS page navigation
 *************************************************************************** */

@keyframes hns-c-cms-nav--rotate {
  from {
    transform: rotateZ(-136deg);
  }
  to {
    transform: rotateZ(224deg);
  }
}

.hns-c-cms-nav {
  --hns-c-cms-nav--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-cms-nav--FontWeight: var(--hns-global--FontWeight-heavy);
  --hns-c-cms-nav--LineHeight: 1;

  --hns-c-cms-nav--tn--FontSize: var(--hns-global--FontSize-title--sm);
  --hns-c-cms-nav--xs--FontSize: var(--hns-global--FontSize-title--lg);
  --hns-c-cms-nav--md--FontSize: var(--hns-global--FontSize-title--2xl);
  --hns-c-cms-nav--hg-lg--FontSize: var(--hns-global--FontSize-title--3xl);
  --hns-c-cms-nav--FontSize: var(--hns-c-cms-nav--tn--FontSize);

  --hns-c-cms-nav--Font: var(--hns-global--FontWeight-heavy) var(--hns-global--FontSize-title--2xs) var(--hns-global--FontFamily-special);

  --hns-c-cms-nav--tn--Gap: .875rem;  /* 14px */
  --hns-c-cms-nav--md--Gap: 1.125rem;  /* 18px */
  --hns-c-cms-nav--Gap: var(--hns-c-cms-nav--tn--Gap);


  /**
   * Rock
   */

  --hns-c-cms-nav__rock--Animation: hns-c-cms-nav--rotate var(--hns-global--Duration--hg) linear infinite;
  --hns-c-cms-nav__rock--BlockSize: .8em;  /* eyeballed */
}

/**
 * Extrasmall
 */

@media all and (min-width: 23rem) {
  .hns-c-cms-nav {
    --hns-c-cms-nav--FontSize: var(--hns-c-cms-nav--xs--FontSize);
  }
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  .hns-c-cms-nav {
    --hns-c-cms-nav--FontSize: var(--hns-c-cms-nav--md--FontSize);
    --hns-c-cms-nav--Gap: var(--hns-c-cms-nav--md--Gap);
  }
}

/**
 * Twice extralarge / Large
 */

@media all and (min-width: 90rem) and (min-height: 57.5rem) {
  .hns-c-cms-nav {
    --hns-c-cms-nav--FontSize: var(--hns-c-cms-nav--hg-lg--FontSize);
  }
}



/**
 * Component
 * 1: reset
 */

.hns-c-cms-nav {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font: var(--hns-c-cms-nav--FontWeight) var(--hns-c-cms-nav--FontSize) var(--hns-c-cms-nav--FontFamily);
  gap: var(--hns-c-cms-nav--Gap);
  justify-content: space-between;
  line-height: var(--hns-c-cms-nav--LineHeight);
  list-style-type: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
  text-transform: uppercase;
}



/**
 * Item
 * 1: contain link
 */

.hns-c-cms-nav li {
  position: relative;  /* 1 */
}



/**
 * Rock
 */

.hns-c-cms-nav li > svg {
  block-size: var(--hns-c-cms-nav__rock--BlockSize);
  display: none;
}

.hns-c-cms-nav li.hns-m-selected > svg {
  animation: var(--hns-c-cms-nav__rock--Animation);
  display: inline-block;
}

@supports not (block-size: var(--hns-c-cms-nav__rock--BlockSize)) {
  .hns-c-cms-nav li > svg {
    height: var(--hns-c-cms-nav__rock--BlockSize);
  }
}



/**
 * Link
 * 1: reset
 */

.hns-c-cms-nav a {
  text-decoration: none;  /* 1 */
}

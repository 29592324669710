/** ***************************************************************************
 * Organisms / Forms / Fieldset
 *************************************************************************** */

:root {
  --hns-c-fieldset--columncount: 1;

  --hns-c-fieldset--ColumnGap: var(--hns-global--content-grid--gutter-width);
  --hns-c-fieldset--RowGap: 1rem;
}



/**
 * Component
 * 1: reset
 */

.hns-c-fieldset {
  border: 0;  /* 1 */
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: var(--hns-c-fieldset--ColumnGap);
  grid-row-gap: var(--hns-c-fieldset--RowGap);
  grid-template-columns: repeat(var(--hns-c-fieldset--columncount), 1fr);
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

/* monstruo */

  /* config */

    .monstruo {
      --height-image: calc(.37 * var(--hns-global--content-grid--content-width));
      --padding: 5rem; /* vertical padding in sectionings */
      --slope: 18vw;
    }

  /* layout */

    @supports (mask-image: var(--gradient-mask)) {
      .monstruo > * {
        box-sizing: border-box;
        width: 100%;
      }

      .monstruo > img {
        display: block;
        object-fit: cover;
      }

      .monstruo > :not(.hns-c-sectioning) {
        --margin-after: 0px;
        --margin-before: 0px;
        height: calc(
          var(--height-image)
          + var(--margin-before)
          + var(--margin-after)
        );
      }

      .monstruo > *:nth-child(n+2) {
        margin-top: calc(-1 * var(--slope));
      }

      .monstruo > :not(.hns-c-sectioning):nth-child(n+2) {
        --margin-before: var(--slope);
      }

      .monstruo > :not(img):nth-child(n+2) {
        padding-top: calc(var(--slope) + var(--padding));
      }

      .monstruo > :not(.hns-c-sectioning):nth-last-child(n+2) {
        --margin-after: var(--slope);
      }

      .monstruo > :not(img):nth-last-child(n+2) {
        padding-bottom: calc(var(--slope) + var(--padding));
      }

      .monstruo {
        --gradient-mask: linear-gradient(
          var(--angle-cut),
          transparent 0,
          transparent var(--position-cut),
          black var(--position-cut),
          black 100%
        );
        --position-cut: 17.8vw;
      }

      .monstruo-down {
        --angle-cut: 190deg;
      }

      .monstruo-up {
        --angle-cut: -190deg;
      }

      .monstruo > *:nth-child(n+2) {
        mask-image: var(--gradient-mask);
      }
    }

    .monstruo > .hns-c-sectioning:last-child {
      padding-bottom: 5rem;
    }

/** ***************************************************************************
 * Atoms / Typography / Address
 *************************************************************************** */

:root {
  /**
   * Address
   */

  --hns-c-address--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-address--FontWeight: var(--hns-global--FontWeight-medium);
}



/**
 * Component
 * 1: reset
 */

address {
  font-family: var(--hns-c-address--FontFamily);
  font-style: normal;  /* 1 */
  font-weight: var(--hns-c-address--FontWeight);
}

address > p {
  margin: 0;  /* 1 */
}

/** ***********************************************************************************
 * Molecules / Social links
 *********************************************************************************** */

.hns-c-social-links {
  --hns-c-social-links--Gap: 1.125rem;  /* 18px */
  --hns-c-social-links--FlexDirection: row;


  /**
   * Icon
   */

  --hns-c-social-links__icon--BackgroundColor: currentcolor;
  --hns-c-social-links__icon--BlockSize: 2rem;  /* 32px */
  --hns-c-social-links__icon--InlineSize: auto;
  --hns-c-social-links__icon--Color: white;
}


/**
 * Component
 * 1: reset
 */

.hns-c-social-links {
  display: flex;
  gap: var(--hns-c-social-links--Gap);
  flex-direction: var(--hns-c-social-links--FlexDirection);
  list-style-type: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}



/**
 * Icon
 */

.hns-c-social-links svg {
  --color-background: var(--hns-c-social-links__icon--BackgroundColor);
  --color-icon: var(--hns-c-social-links__icon--Color);
  block-size: var(--hns-c-social-links__icon--BlockSize);
  inline-size: var(--hns-c-social-links__icon--InlineSize);
}

@supports not(block-size: var(--hns-c-social-links__icon--BlockSize)) {
  .hns-c-social-links svg {
    height: var(--hns-c-social-links__icon--BlockSize);
    width: var(--hns-c-social-links__icon--InlineSize);
  }
}

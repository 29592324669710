/** ***************************************************************************
 * Crag card holder
 *************************************************************************** */

:root {

  --hns-c-card-holder--angle: 2deg;
  --hns-c-card-holder--cut-position: var(--hns-c-card-holder--Gap);

  --hns-c-card-holder--tn--columncount: 1;
  --hns-c-card-holder--sm--columncount: 2;
  --hns-c-card-holder--lg--columncount: 3;
  --hns-c-card-holder--columncount: var(--hns-c-card-holder--tn--columncount);

  --hns-c-card-holder--Gap: .8rem;  /* 13px */

  --hns-c-card-holder--tn--GridAutoRows: auto;
  --hns-c-card-holder--sm--GridAutoRows: 1fr;
  --hns-c-card-holder--GridAutoRows: var(--hns-c-card-holder--tn--GridAutoRows);
}

/**
 * Small
 */

@media all and (min-width: 34rem) {
  :root {
    --hns-c-card-holder--columncount: var(--hns-c-card-holder--sm--columncount);
    --hns-c-card-holder--GridAutoRows: var(--hns-c-card-holder--sm--GridAutoRows);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  :root {
    --hns-c-card-holder--columncount: var(--hns-c-card-holder--lg--columncount);
  }
}



/**
 * Component
 * 1: reset
 * 2: reset list styling
 */

.hns-c-card-holder {
  --hns-c-list__item--MarginBlockStart: 0;  /* 2 */
  --hns-c-list__item--PaddingBlockStart: 0;  /* 2 */
  --hns-c-list__item--PaddingInlineStart: 0;  /* 2 */
  display: grid;
  grid-auto-rows: var(--hns-c-card-holder--GridAutoRows);
  grid-row-gap: var(--hns-c-card-holder--Gap);
  grid-template-columns: repeat(var(--hns-c-card-holder--columncount), 1fr);
  list-style-type: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

.hns-c-card-holder > li::before {
  display: none;  /* 2 */
}



/**
 * Crag card
 */

.hns-c-card-holder .hns-c-crag-card {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}



/**
 * Old code
 */

/**
 * Small
 */

@media (min-width: 34rem) {
  .hns-c-card-holder > *,
  .hns-c-card-holder .hns-c-crag-card {
    --gradient-mask: linear-gradient(
      var(--angle-cut),
      transparent 0,
      transparent calc(var(--hns-c-card-holder--cut-position) - 1px),
      gray var(--hns-c-card-holder--cut-position),
      black 0,
      black 100%
    );
  }

  .hns-c-card-holder .hns-c-crag-card {
    min-height: calc(var(--hns-global--content-grid--content-width) / var(--hns-c-card-holder--columncount) * .6);
  }

  .hns-c-card-holder > :nth-last-of-type(n+2) .hns-c-crag-card {
    /*margin-bottom: 0;*/
  }

  .hns-c-card-holder > :nth-of-type(2n+1) .hns-c-crag-card {
    --hns-c-crag-card__left-cut--Width: 0px;
    --hns-c-crag-card__right-cut--Width: var(--hns-c-card-holder--Gap);
  }

  .hns-c-card-holder > :nth-of-type(2n) .hns-c-crag-card {
    --hns-c-crag-card__left-cut--Width: var(--hns-c-card-holder--Gap);
    --hns-c-crag-card__right-cut--Width: 0px;
  }

  .hns-c-card-holder > :nth-of-type(4n+1) .hns-c-crag-card {
    --angle-cut: calc(270deg + var(--hns-c-card-holder--angle));
    /*--angle-cut: 272deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(4n+2) .hns-c-crag-card {
    --angle-cut: calc(90deg + var(--hns-c-card-holder--angle));
    /*--angle-cut: 92deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(4n+3) .hns-c-crag-card {
    --angle-cut: calc(270deg - var(--hns-c-card-holder--angle));
    /*--angle-cut: 268deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(4n) .hns-c-crag-card {
    --angle-cut: calc(90deg - var(--hns-c-card-holder--angle));
    /*--angle-cut: 88deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }
}

/**
 * Large
 */

@media (min-width: 64rem) {
  .hns-c-card-holder > :nth-of-type(3n+1) .hns-c-crag-card {
    --hns-c-crag-card__left-cut--Width: 0px;
    --hns-c-crag-card__right-cut--Width: var(--hns-c-card-holder--Gap);
  }

  .hns-c-card-holder > :nth-of-type(3n+2) .hns-c-crag-card {
    --hns-c-crag-card__left-cut--Width: var(--hns-c-card-holder--Gap);
    --hns-c-crag-card__right-cut--Width: var(--hns-c-card-holder--Gap);
  }

  .hns-c-card-holder > :nth-of-type(3n) .hns-c-crag-card {
    --hns-c-crag-card__left-cut--Width: var(--hns-c-card-holder--Gap);
    --hns-c-crag-card__right-cut--Width: 0px;
  }

  .hns-c-card-holder > :nth-of-type(6n+1) .hns-c-crag-card {
    --angle-cut: calc(270deg + var(--hns-c-card-holder--angle));
    /*--angle-cut: 272deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(6n+2) {
    --angle-cut: calc(90deg + var(--hns-c-card-holder--angle));
    /*--angle-cut: 92deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(6n+2) .hns-c-crag-card {
    --angle-cut: calc(270deg - var(--hns-c-card-holder--angle));
    /*--angle-cut: 268deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(6n+3) .hns-c-crag-card {
    --angle-cut: calc(90deg - var(--hns-c-card-holder--angle));
    /*--angle-cut: 88deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(6n+4) .hns-c-crag-card {
    --angle-cut: calc(270deg - var(--hns-c-card-holder--angle));
    /*--angle-cut: 268deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(6n+5) {
    --angle-cut: calc(90deg - var(--hns-c-card-holder--angle));
    /*--angle-cut: 88deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);

  }

  .hns-c-card-holder > :nth-of-type(6n+5) .hns-c-crag-card {
    --angle-cut: calc(270deg + var(--hns-c-card-holder--angle));
    /*--angle-cut: 272deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }

  .hns-c-card-holder > :nth-of-type(6n) .hns-c-crag-card {
    --angle-cut: calc(90deg + var(--hns-c-card-holder--angle));
    /*--angle-cut: 92deg;*/ /* Edge doesn't like calc here */
    mask-image: var(--gradient-mask);
  }
}

/** ***************************************************************************
 * Organisms / CMS plugins / Video player
 *************************************************************************** */

:root {
  --hns-c-video--tn--Height: 16.875rem;  /* 270px */
  --hns-c-video--sm--Height: 23.25rem;  /* 373px */
  --hns-c-video--md--Height: 32.25rem;  /* 515px? */
  --hns-c-video--hg--Height: 41rem;  /* 657px */
  --hns-c-video--hg--Height: 44.25rem;  /* 707px */
  --hns-c-video--Height: var(--hns-c-video--tn--Height);


  /**
   * Play button
   */

  --hns-c-video__button--BackgroundColor: rgba(
    var(--hns-global--palette-white--100-r),
    var(--hns-global--palette-white--100-g),
    var(--hns-global--palette-white--100-b),
    .35
  );
  --hns-c-video__button--Color: var(--hns-global--palette-white);
  --hns-c-video__button--TransitionDuration: 300ms;

  --hns-c-video__button--tn--Padding: 3.25rem;  /* 51px */
  --hns-c-video__button--sm--Padding: 7.5rem;  /* 119px */
  --hns-c-video__button--Padding: var(--hns-c-video__button--tn--Padding);

  --hns-c-video__button--hover--Padding: calc(var(--hns-c-video__button--Padding) * .7);


  /**
   * Play button icon
   */

  --hns-c-video__button-icon--Width: 4.5rem;  /* 72px */


  /**
   * Overlay
   */

  --hns-c-video__overlay--BackgroundColor: var(--hns-global--palette-gray);
  --hns-c-video__overlay--Opacity: .3;
}

/**
 * Small
 */

@media all and (min-width: 48rem) {
  :root {
    --hns-c-video--Height: var(--hns-c-video--sm--Height);
    --hns-c-video__button--Padding: var(--hns-c-video__button--sm--Padding);
  }
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  :root {
    --hns-c-video--Height: var(--hns-c-video--md--Height);
  }
}

/**
 * Twice extralarge
 */

@media all and (min-width: 90rem) {
  :root {
    --hns-c-video--Height: var(--hns-c-video--hg--Height);
  }
}

/**
 * Twice extralarge
 */

@media all and (min-width: 120rem) {
  :root {
    --hns-c-video--Height: var(--hns-c-video--hg--Height);
  }
}



/**
 * Component
 * 1: contain poster image and play button
 * 2: center play button
 */
.hns-c-video-player {
  align-items: center;  /* 2 */
  display: flex;  /* 2 */
  height: var(--hns-c-video--Height);
  justify-content: center;  /* 2 */
  position: relative;  /* 1 */
}



/**
 * Poster image
 */

.hns-c-video-player > img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
  width: 100%;
}



/**
 * Play button
 * 1: reset
 * 2: hide when javascript is disabled
 * 3: extend button over the whole component
 */

.hns-c-video-player > button {
  align-items: center;
  appearance: none;  /* 1 */
  background-color: var(--hns-c-video__button--BackgroundColor);
  border-radius: 50%;
  border-width: 0;  /* 1 */
  color: var(--hns-c-video__button--Color);
  cursor: pointer;
  display: none;  /* 2 */
  justify-items: center;
  margin: 0;  /* 1 */
  padding: var(--hns-c-video__button--Padding);
  transition: padding var(--hns-c-video__button--TransitionDuration) ease-out;
  z-index: 2;
}

  .hns-c-video-player > button:hover {
    padding: var(--hns-c-video__button--hover--Padding);
  }

  .hns-c-video-player.hns-m-js > button {
    display: block;  /* 2 */
  }



/**
 * Play button icon
 */

.hns-c-video-player > button svg {
  width: var(--hns-c-video__button-icon--Width);
}



/**
 * Overlay
 */

.hns-c-video-player::before {
  background-color: var(--hns-c-video__overlay--BackgroundColor);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: var(--hns-c-video__overlay--Opacity);
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

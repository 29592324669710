/* latest news */

  /* typography */

    .latest-news > header {
      text-align: center;
    }

    .latest-news > header > :first-child {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--2xl);
      font-weight: var(--hns-global--FontWeight-heavy);
    }

    .latest-news > header .subtitle {
      font-family: var(--hns-global--FontFamily-normal);
      font-size: var(--hns-global--FontSize-paragraph--sm);
      font-weight: var(--hns-global--FontWeight-bold);
    }

    .latest-news > footer {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--xs);
      font-weight: var(--hns-global--FontWeight-heavy);
      text-align: center;
    }

    /*
     * md
     */
    @media (min-width: 48rem) {
      .latest-news > header > :first-child {
        font-size: var(--hns-global--FontSize-title--4xl);
      }

      .latest-news > header .subtitle {
        font-size: var(--hns-global--FontSize-paragraph--md);
      }
    }

    /*
     * hg
     */
    @media (min-width: 90rem) {
      .latest-news > header,
      .latest-news > footer {
        text-align: left;
      }
    }

  /* colors */

    .latest-news {
      color: var(--hns-global--palette-blue--100);
    }

    .latest-news > footer {
      background-color: rgba(
        var(--hns-global--palette-yellow--40-r),
        var(--hns-global--palette-yellow--40-g),
        var(--hns-global--palette-yellow--40-b),
        1
      );
    }

  /* layout */

    .latest-news {
      --column-content-end: -4;
      --column-content-start: 4;
      --column-header-end: var(--column-content-end);
      --column-header-start: var(--column-content-start);
      --margin-content-after: 3.5rem;  /* 56px */
      --margin-content-before: 2.5rem; /* 40px */
      --padding-footer-after: 1.5rem;  /* 26px */
      --padding-footer-before: 2rem;   /* 31 px*/
      display: grid;
      grid-template-columns: var(--columns-default);
    }

    .latest-news > * {
      grid-column: var(--column-content-start) / var(--column-content-end);
    }

    .latest-news > header {
      grid-column: var(--column-header-start) / var(--column-header-end);
    }

    .latest-news > header > :first-child {
      margin-bottom: 1rem;
    }

    .latest-news > header + * {
      margin-bottom: var(--margin-content-after);
      margin-top: var(--margin-content-before);
    }

    .latest-news > header > * {
      margin: 0;
    }

    /* Edge needs this */
    .latest-news .hns-c-post-item {
      position: relative;
      z-index: 1;
    }

    .latest-news > .post-list > :nth-of-type(n+3) {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    .latest-news > footer {
      display: grid;
      grid-column: viewport-start / viewport-end;
      grid-template-columns: var(--columns-default);
      grid-template-rows:
        var(--padding-footer-before)
        [content]
        auto
        var(--padding-footer-after);
    }

    .latest-news > footer > a {
      grid-column: var(--column-content-start) / var(--column-content-end);
      grid-row: content / span 1;
    }

      .latest-news > footer svg {
        width: 2.2em;
        vertical-align: middle;
        transition: transform var(--hns-global--Duration--sm) linear;
        margin: 1rem auto 0 auto;
        height: 2.2em;
        display: block;
      }

      .latest-news > footer > a:hover svg {
        transform: scale(1.1);
      }

    /*
     * sm
     */
    @media (min-width: 34rem) {
      .latest-news {
        --column-content-end: -5;
        --column-content-start: 5;
      }
    }

    /*
     * md
     */
    @media (min-width: 48rem) {
      .latest-news {
        --margin-content-after: 4rem;    /* 65px */
        --padding-footer-after: 2rem;    /* 30px */
        --padding-footer-before: 2.5rem; /* 38 px*/
      }

      .latest-news > footer svg {
        display: inline;
        margin: 0 0 0 1em;
      }
    }

    /*
     * lg
     */
    @media (min-width: 64rem) {
      .latest-news {
        --column-header-end: -6;
        --column-header-start: 6;
        --margin-content-after: 1.5rem; /* 23px */
        --margin-content-before: 3rem;  /* 48px */
      }

      .latest-news .post-list > :nth-child(2n) {
        margin-top: 3rem;
      }
    }

    /*
     * hg
     */
    @media (min-width: 90rem) {
      .latest-news {
        --column-content-end: -7;
        --column-content-start: 7;
        --column-header-end: -5;
        --column-header-start: 7;
        --deviation-footer: 19.75rem; /* 316px */
        --margin-content-after: 0;
        --padding-footer-after: 2.5rem; /* 40px */
        --padding-footer-before: 2.5rem;
      }

      .latest-news > .post-list {
        margin-bottom: 0;
        z-index: 1; /* over the footer */
      }

      .latest-news > .post-list > :nth-of-type(3) {
        opacity: 1;
        pointer-events: all;
        position: inherit;
      }

      .latest-news > .post-list + footer {
        --padding-footer-before: calc(
          var(--deviation-footer) + 2.5rem
        );
        margin-top: calc(-1 * var(--deviation-footer));
      }
    }

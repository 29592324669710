/* page footer */

  /* typography */

    body > footer {
      font-size: var(--hns-global--FontSize-paragraph--sm);
    }

    /* credits link */
    body > footer > a:first-of-type {
      font-family: Oswald;
      font-size: var(--hns-global--FontSize-title--xs);
      font-weight: var(--hns-global--FontWeight-heavy);
      text-transform: uppercase;
    }

    /* privacy link */
    body > footer > a:last-of-type {
      font-weight: var(--hns-global--FontWeight-bold);
    }

  /* colors */

    body > footer {
      color: var(--hns-global--palette-dark);
    }

    /* credits link */
    body > footer > a:first-of-type {
      color: var(--hns-global--palette-blue);
    }

    body > footer > hr {
      color: var(--hns-global--palette-blue);
    }

  /* layout */

    body > footer {
      align-items: center;
      display: grid;
      grid-template-columns: var(--columns-default);
      min-height: 3rem;
      row-gap: 1rem;
    }

    body > footer > * {
      grid-column: 4 / -4;
      margin: 0;
    }

    body > footer > .hns-c-cta {
      grid-column: viewport-start / viewport-end;
    }

    /* divider */
    body > footer > hr {
      width: 40%;
    }

    body > footer > a:last-of-type {
      margin-bottom: 1em;
    }

    @media (min-width: 48rem) { /* md */
      body > footer {
        --column-credits: 10 / -9;
        --column-divider: 9 / span 1;
        --column-link-credits: 5 / span 3;
        --column-privacy: -9 / span 4;
      }

      /* credits link */
      body > footer > a:first-of-type {
        align-self: center;
        grid-column: var(--column-link-credits);
        justify-self: start;
      }

      /* divider */
      body > footer > hr {
        align-self: stretch;
        grid-column: var(--column-divider);
        height: 100%;
        width: 1px;
      }

      /* credits */
      body > footer > hr + div {
        grid-column: var(--column-credits);
        justify-self: center;
      }

      /* privacy link */
      body > footer > a:last-of-type {
        grid-column: var(--column-privacy);
        justify-self: end;
        margin-bottom: 0;
        text-align: right;
      }
    }

    @media (min-width: 64rem) { /* lg */
      body > footer {
        --column-credits: 8 / -8;
        --column-divider: 7 / span 1;
        --column-link-credits: 4 / span 3;
        --column-privacy: -7 / -4;
      }
    }

    @media (min-width: 90rem) { /* hg */
      body > footer {
        --column-credits: 8 / -13;
        --column-divider: 7 / span 1;
        --column-link-credits: 5 / span 2;
        --column-privacy: -13 / span 4;
      }
    }

/** ***************************************************************************
 * Organisms / Site navigation
 *************************************************************************** */

.hns-c-site-nav {

  --hns-p-site-nav--tn--startspacer: minmax(7vh, 3rem);
  --hns-p-site-nav--lg--startspacer: minmax(7vh, 8rem);  /* --hns-c-tools--PaddingBlockStart */
  --hns-p-site-nav--hg--startspacer: minmax(7vh, 9.5rem);  /* --hns-c-tools--PaddingBlockStart */
  --hns-p-site-nav--startspacer: var(--hns-p-site-nav--tn--startspacer);

  --hns-p-site-nav--tn--endspacer: minmax(3vh, 1.5rem);
  --hns-p-site-nav--lg--endspacer: minmax(3vh, 3.5rem);
  --hns-p-site-nav--endspacer: var(--hns-p-site-nav--tn--endspacer);

  --hns-c-site-nav--AccentColor: var(--hns-global--palette-blue--100);
  --hns-c-site-nav--BackgroundColor: var(--hns-global--palette-blue--80);
  --hns-c-site-nav--Color: var(--hns-global--palette-white);
  --hns-c-site-nav--TemplateColumns: var(--hns-global--page-grid--TemplateColumns);

  --hns-c-site-nav--tn--TemplateRows:
    auto                                /* languages */
    var(--hns-p-site-nav--startspacer)  /* spacer */
    minmax(auto, 1fr)                   /* cms navigation */
    var(--hns-p-site-nav--endspacer)    /* spacer */
    auto                                /* reserved area button */
    1.375rem                            /* spacer */
    auto;                                /* evil links */
  --hns-c-site-nav--md--TemplateRows:
    auto                                /* languages */
    var(--hns-p-site-nav--startspacer)  /* spacer */
    auto                                /* cms navigation */
    var(--hns-p-site-nav--endspacer)    /* spacer */
    1px                                 /* divider */
    1.75rem                             /* spacer */
    auto                                /* reserved area button + evil links */
    1fr;                                /* spacer */
  --hns-c-site-nav--lg--TemplateRows:
    0                                   /* nothing */
    var(--hns-p-site-nav--startspacer)  /* spacer */
    auto                                /* cms navigation */
    var(--hns-p-site-nav--endspacer)    /* spacer */
    1px                                 /* divider */
    1.75rem                             /* spacer */
    auto;                               /* reserved area button */
  --hns-c-site-nav--TemplateRows: var(--hns-c-site-nav--tn--TemplateRows);

  --hns-c-site-nav--tn--PaddingBlockEnd: 1.75rem;  /* 29px */
  --hns-c-site-nav--PaddingBlockEnd: var(--hns-c-site-nav--tn--PaddingBlockEnd);

  --hns-c-site-nav--tn--PaddingBlockStart: 1.5rem;  /* --hns-c-tools--PaddingBlockStart */
  --hns-c-site-nav--md--PaddingBlockStart: 3.5rem;  /* --hns-c-tools--PaddingBlockStart */
  --hns-c-site-nav--lg--PaddingBlockStart: 0;
  --hns-c-site-nav--PaddingBlockStart: var(--hns-c-site-nav--tn--PaddingBlockStart);


  /**
   * Language chooser
   */

  --hns-c-site-nav__languages--Gap: 1.75rem;  /* 27px */

  --hns-c-site-nav__languages--tn--GridColumn: 4 / -7;
  --hns-c-site-nav__languages--md--GridColumn: 5 / -7;
  --hns-c-site-nav__languages--GridColumn: var(--hns-c-site-nav__languages--tn--GridColumn);

  --hns-c-site-nav__languages--tn--GridRow: 1 / span 1;
  --hns-c-site-nav__languages--GridRow: var(--hns-c-site-nav__languages--tn--GridRow);


  /**
   * CMS page navigation
   */

  --hns-c-site-nav__cms--tn--GridColumn: 4 / -4;
  --hns-c-site-nav__cms--md--GridColumn: 5 / -5;
  --hns-c-site-nav__cms--hg--GridColumn: 6 / -6;
  --hns-c-site-nav__cms--GridColumn: var(--hns-c-site-nav__cms--tn--GridColumn);

  --hns-c-site-nav__cms--tn--GridRow: 3 / span 1;
  --hns-c-site-nav__cms--GridRow: var(--hns-c-site-nav__cms--tn--GridRow);


  /**
   * Divider
   */

  --hns-c-site-nav__divider--GridColumn: var(--hns-c-site-nav__cms--GridColumn);

  --hns-c-site-nav__divider--tn--GridRow: 5 / span 1;
  --hns-c-site-nav__divider--GridRow: var(--hns-c-site-nav__divider--tn--GridRow);


  /**
   * Reserved area button
   */

  --hns-c-site-nav__reserved--tn--GridColumn: 4 / -1;
  --hns-c-site-nav__reserved--md--GridColumn: 5 / span 14;
  --hns-c-site-nav__reserved--lg--GridColumn: 5 / -7;
  --hns-c-site-nav__reserved--hg--GridColumn: 6 / -6;
  --hns-c-site-nav__reserved--GridColumn: var(--hns-c-site-nav__reserved--tn--GridColumn);

  --hns-c-site-nav__reserved--tn--GridRow: 5 / span 1;
  --hns-c-site-nav__reserved--md--GridRow: 7 / span 1;
  --hns-c-site-nav__reserved--GridRow: var(--hns-c-site-nav__reserved--tn--GridRow);


  /**
   * Social links
   */

  --hns-c-site-nav__social--RowGap: .8em;  /* 12px */
  --hns-c-site-nav__social--Font: var(--hns-global--FontWeight-regular) var(--hns-global--FontSize-paragraph--xs) var(--hns-global--FontFamily-normal);

  --hns-c-site-nav__social--tn--AlignItems: center;
  --hns-c-site-nav__social--md--AlignItems: flex-start;
  --hns-c-site-nav__social--AlignItems: var(--hns-c-site-nav__social--tn--AlignItems);

  --hns-c-site-nav__social--tn--FlexDirection: row;
  --hns-c-site-nav__social--md--FlexDirection: column;
  --hns-c-site-nav__social--FlexDirection: var(--hns-c-site-nav__social--tn--FlexDirection);

  --hns-c-site-nav__social--tn--GridColumn: 4 / -4;
  --hns-c-site-nav__social--md--GridColumn: 20 / span 6;
  --hns-c-site-nav__social--GridColumn: var(--hns-c-site-nav__social--tn--GridColumn);

  --hns-c-site-nav__social--tn--GridRow: 7 / span 1;
  --hns-c-site-nav__social--md--GridRow: var(--hns-c-site-nav__reserved--GridRow);
  --hns-c-site-nav__social--GridRow: var(--hns-c-site-nav__social--tn--GridRow);

  --hns-c-site-nav__social--tn--JustifySelf: stretch;
  --hns-c-site-nav__social--md--JustifySelf: end;
  --hns-c-site-nav__social--JustifySelf: var(--hns-c-site-nav__social--tn--JustifySelf);


  /**
   * Rocks
   */

  --hns-c-site-nav__rock--InsetInlineEnd: 18.5vw;
  --hns-c-site-nav__rock--Opacity: .6;
  --hns-c-site-nav__rock--TransitionDelay: 0s;
  --hns-c-site-nav__rock--TransitionDuration: var(--hns-global--Duration--sm);

  --hns-c-site-nav__rock--InsetBlockStart: calc(
    8rem        /* --hns-c-tools--PaddingBlockStart */
    + 1.875rem  /* hamburger icon */
    + 1rem      /* var(--hs-c-nav-switch--RowGap) */
  );

  --hns-c-site-nav__rock-first--BlockSize: 10.125rem;  /* 162px */
  --hns-c-site-nav__rock-second--BlockSize: 6.25rem;  /* 100px */
  --hns-c-site-nav__rock-third--BlockSize: 3.5rem;  /* 56px */

  --hns-c-site-nav__rock-first--Transform: translateX(-27.36%) translateY(77.16%) rotateZ(207deg);

  --hns-c-site-nav__rock-second--Transform: translateX(30.65%) translateY(5%) rotateZ(136deg);

  --hns-c-site-nav__rock-third--Transform: translateX(-252.17%) translateY(35.71%) rotateZ(136deg);

}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  .hns-c-site-nav {
    --hns-c-site-nav--PaddingBlockStart: var(--hns-c-site-nav--md--PaddingBlockStart);
    --hns-c-site-nav--TemplateRows: var(--hns-c-site-nav--md--TemplateRows);
    --hns-c-site-nav__cms--GridColumn: var(--hns-c-site-nav__cms--md--GridColumn);
    --hns-c-site-nav__languages--GridColumn: var(--hns-c-site-nav__languages--md--GridColumn);
    --hns-c-site-nav__reserved--GridColumn: var(--hns-c-site-nav__reserved--md--GridColumn);
    --hns-c-site-nav__reserved--GridRow: var(--hns-c-site-nav__reserved--md--GridRow);
    --hns-c-site-nav__social--AlignItems: var(--hns-c-site-nav__social--md--AlignItems);
    --hns-c-site-nav__social--FlexDirection: var(--hns-c-site-nav__social--md--FlexDirection);
    --hns-c-site-nav__social--GridColumn: var(--hns-c-site-nav__social--md--GridColumn);
    --hns-c-site-nav__social--GridRow: var(--hns-c-site-nav__social--md--GridRow);
    --hns-c-site-nav__social--JustifySelf: var(--hns-c-site-nav__social--md--JustifySelf);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-site-nav {
    --hns-c-site-nav--PaddingBlockStart: var(--hns-c-site-nav--lg--PaddingBlockStart);
    --hns-c-site-nav__reserved--GridColumn: var(--hns-c-site-nav__reserved--lg--GridColumn);
    --hns-p-site-nav--endspacer: var(--hns-p-site-nav--lg--endspacer);
    --hns-p-site-nav--startspacer: var(--hns-p-site-nav--lg--startspacer);
  }
}

/**
 * Twice extralarge
 */

@media all and (min-width: 90rem) {
  .hns-c-site-nav {
    --hns-c-site-nav__cms--GridColumn: var(--hns-c-site-nav__cms--hg--GridColumn);
    --hns-c-site-nav__reserved--GridColumn: var(--hns-c-site-nav__reserved--hg--GridColumn);
    --hns-p-site-nav--startspacer: var(--hns-p-site-nav--hg--startspacer);
  }
}



/**
 * Component
 * 1: extend background to the left
 * 2: contain rocks
 */

.hns-c-site-nav {
  accent-color: var(--hns-c-site-nav--AccentColor);
  background-color: var(--hns-c-site-nav--BackgroundColor);
  box-shadow: 0 0 0 100vmax var(--hns-c-site-nav--BackgroundColor);  /* 1 */
  clip-path: inset(0 0 0 -100vmax);  /* 1 */
  color: var(--hns-c-site-nav--Color);
  display: grid;
  font-family: var(--hns-global--FontFamily-special);
  font-weight: var(--hns-global--FontWeight-bold);
  grid-template-columns: var(--hns-c-site-nav--TemplateColumns);
  grid-template-rows: var(--hns-c-site-nav--TemplateRows);
  padding-block: var(--hns-c-site-nav--PaddingBlockStart) var(--hns-c-site-nav--PaddingBlockEnd);
  position: relative;  /* 2 */
}

@supports not (padding-block: var(--hns-c-site-nav--PaddingBlockStart) var(--hns-c-site-nav--PaddingBlockEnd)) {
  .hns-c-site-nav {
    padding-bottom: var(--hns-c-site-nav--PaddingBlockEnd);
    padding-top: var(--hns-c-site-nav--PaddingBlockStart);
  }
}



/**
 * Language chooser
 */

.hns-c-site-nav .hns-c-language-chooser {
  --hns-c-language-chooser--Gap: var(--hns-c-site-nav__languages--Gap);
  --hns-c-language-chooser__divider--Display: none;
  grid-column: var(--hns-c-site-nav__languages--GridColumn);
  grid-row: var(--hns-c-site-nav__languages--GridRow);
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-site-nav .hns-c-language-chooser {
    display: none;
  }
}



/**
 * CMS page navigation
 */

.hns-c-site-nav .hns-c-cms-nav {
  grid-column: var(--hns-c-site-nav__cms--GridColumn);
  grid-row: var(--hns-c-site-nav__cms--GridRow);
}



/**
 * Divider
 * 1: reset
 */

.hns-c-site-nav > hr {
  border: 0;
  background-color: currentcolor;
  height: 1px;  /* 1 */
  display: none;
  grid-column: var(--hns-c-site-nav__divider--GridColumn);
  grid-row: var(--hns-c-site-nav__divider--GridRow);
  margin: 0;  /* 1 */
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  .hns-c-site-nav > hr {
    display: block;
  }
}



/**
 * Reserved area button
 */

.hns-c-site-nav .hns-c-reserved-button {
  align-self: start;
  grid-column: var(--hns-c-site-nav__reserved--GridColumn);
  grid-row: var(--hns-c-site-nav__reserved--GridRow);
}



/**
 * Social links
 */

.hns-c-site-nav__social {
  align-items: var(--hns-c-site-nav__social--AlignItems);
  align-self: start;
  display: flex;
  flex-direction: var(--hns-c-site-nav__social--FlexDirection);
  font: var(--hns-c-site-nav__social--Font);
  grid-column: var(--hns-c-site-nav__social--GridColumn);
  grid-row: var(--hns-c-site-nav__social--GridRow);
  justify-content: space-between;
  justify-self: var(--hns-c-site-nav__social--JustifySelf);
  row-gap: var(--hns-c-site-nav__social--RowGap);
}

.hns-c-site-nav__social > .hns-c-social-links {
  --hns-c-social-links__icon--BackgroundColor: var(--hns-c-site-nav--AccentColor);
  --hns-c-social-links__icon--Color: var(--hns-c-site-nav--Color);
  align-self: flex-end;
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-site-nav__social {
    display: none;
  }
}



/**
 * Rocks
 * 1: hide rocks
 */

.hns-c-site-nav > svg {
  inset-block-start: var(--hns-c-site-nav__rock--InsetBlockStart);
  inset-inline-end: var(--hns-c-site-nav__rock--InsetInlineEnd);
  opacity: 0;  /* 1 */
  pointer-events: none;
  position: absolute;
  transition: opacity var(--hns-c-site-nav__rock--TransitionDuration) linear var(--hns-c-site-nav__rock--TransitionDelay);
}

.hns-c-site-nav > svg:nth-of-type(1) {
  block-size: var(--hns-c-site-nav__rock-first--BlockSize);
  transform: var(--hns-c-site-nav__rock-first--Transform);
}

.hns-c-site-nav > svg:nth-of-type(2) {
  block-size: var(--hns-c-site-nav__rock-second--BlockSize);
  transform: var(--hns-c-site-nav__rock-second--Transform);
}

.hns-c-site-nav > svg:nth-of-type(3) {
  block-size: var(--hns-c-site-nav__rock-third--BlockSize);
  transform: var(--hns-c-site-nav__rock-third--Transform);
}

/**
 * Large
 * 1: show rocks
 * 2: move rocks
 */

@media all and (min-width: 64rem) {
  .hns-c-site-nav > svg {
    opacity: var(--hns-c-site-nav__rock--Opacity);  /* 1 */
  }
}

@supports not (inset-block-start: var(--hns-c-site-nav__rock--InsetBlockStart)) {
  .hns-c-site-nav > svg {
    right: var(--hns-c-site-nav__rock--InsetInlineEnd);
    top: var(--hns-c-site-nav__rock--InsetBlockStart);
  }

  .hns-c-site-nav > svg:nth-of-type(1) {
    height: var(--hns-c-site-nav__rock-first--BlockSize);
  }

  .hns-c-site-nav > svg:nth-of-type(2) {
    height: var(--hns-c-site-nav__rock-second--BlockSize);
  }

  .hns-c-site-nav > svg:nth-of-type(3) {
    height: var(--hns-c-site-nav__rock-third--BlockSize);
  }
}

/** ***************************************************************************
 * Molecules / Modal / Video
 *************************************************************************** */

:root {
  /**
   * Video player
   */

  --hns-c-modal--m-video__player--AspectRatio: 16 / 9;

  --hns-c-modal--m-video__player--tn--Width: 95%;
  --hns-c-modal--m-video__player--md--Width: 80%;
  --hns-c-modal--m-video__player--Width: var(--hns-c-video__dialog-content--tn--Width);

}



/**
 * Component
 */

.hns-c-modal.hns-m-video {

}



/**
 * Video player
 * 1: reset
 */

.hns-c-modal.hns-m-video video,
.hns-c-modal.hns-m-video iframe {
  max-width: var(--hns-c-modal--m-video__player--Width);
  height: 80%;
  z-index: 2;
}

@supports (aspect-ratio: var(--hns-c-modal--m-video__player--AspectRatio)) {
  .hns-c-modal.hns-m-video video,
  .hns-c-modal.hns-m-video iframe {
    aspect-ratio: var(--hns-c-modal--m-video__player--AspectRatio);
    max-height: 80%;
  }
}

.hns-c-modal.hns-m-video video:focus {
  outline: 0;  /* 1 */
}

/** ***************************************************************************
 * Organisms / Reserved area / Profile data
 *************************************************************************** */

.hns-c-profile {
  --hns-c-profile--Font: var(--hns-global--FontWeight-regular) var(--hns-global--FontSize-label--sm) var(--hns-global--FontFamily-normal);


  /**
   * Heading
   */

  --hns-c-profile__heading--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-label--sm) var(--hns-global--FontFamily-normal);


  /**
   * Text
   */

  --hns-c-profile__text--MarginBlockStart: .3em;


  /**
   * Term
   */

  --hns-c-profile__term--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-label--xs) var(--hns-global--FontFamily-normal);
  --hns-c-profile__term--MarginBlock: .375em;  /* 5px */
}



/**
 * Component
 */

.hns-c-profile {
  font: var(--hns-c-profile--Font);
}



/**
 * Heading
 */

.hns-c-profile > :first-child {
  font: var(--hns-c-profile__heading--Font);
  margin: 0;
}



/**
 * Text
 */

.hns-c-profile p {
  margin-block-start: var(--hns-c-profile__text--MarginBlockStart);
}

@supports not(margin-block-end: 0) {
  .hns-c-profile p {
    margin-top: var(--hns-c-profile__text--MarginBlockStart);
  }
}



/**
 * Term
 */

.hns-c-profile dt {
  font: var(--hns-c-profile__term--Font);
}



/**
 * Value
 * 1: reset
 */

.hns-c-profile dd {
  margin-block: var(--hns-c-profile__term--MarginBlock);
  margin-inline-start: 0;  /* 1 */
}

.hns-c-profile dd::last-of-type {
  margin-block-end: 0;
}

@supports not(margin-block-end: 0) {
  .hns-c-profile dd {
    margin-botom: var(--hns-c-profile__term--MarginBlock);
    margin-left: 0;  /* 1 */
    margin-top: var(--hns-c-profile__term--MarginBlock);
  }

  .hns-c-profile dd::last-of-type {
    margin-bottom: 0;
  }
}

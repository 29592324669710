/** ***************************************************************************
 * Organisms / Reserved area / Navigation
 *************************************************************************** */

.hns-c-reserved-nav {
  --hns-c-reserved-nav--flag: 0;

  --hns-c-reserved-nav--tn--PaddingBlockEnd: var(--hns-c-reserved-nav__switch--BlockSize);
  --hns-c-reserved-nav--lg--PaddingBlockEnd: clamp(2rem, 18.5vh, 9.25rem);
  --hns-c-reserved-nav--PaddingBlockEnd: var(--hns-c-reserved-nav--tn--PaddingBlockEnd);

  --hns-c-reserved-nav--tn--PaddingBlockStart: 0;
  --hns-c-reserved-nav--lg--PaddingBlockStart: clamp(0rem, 12.6vh, 6.25rem);
  --hns-c-reserved-nav--PaddingBlockStart: var(--hns-c-reserved-nav--tn--PaddingBlockStart);

  --hns-c-reserved-nav--tn--RowGap: 1px;
  --hns-c-reserved-nav--lg--RowGap: clamp(.875rem, 2vh, 1.5rem);
  --hns-c-reserved-nav--RowGap: var(--hns-c-reserved-nav--tn--RowGap);


  /**
   * Item
   */

  --hns-c-reserved-nav__item--TransitionDuration: var(--hns-global--Duration--sm);
  --hns-c-reserved-nav__item--TransitionDelay: .05s;

  --hns-c-reserved-nav__item--tn--BackgroundColor: var(--hns-global--palette-blue);
  --hns-c-reserved-nav__item--lg--BackgroundColor: transparent;
  --hns-c-reserved-nav__item--BackgroundColor: var(--hns-c-reserved-nav__item--tn--BackgroundColor);

  --hns-c-reserved-nav__item--tn--Color: var(--hns-global--palette-white);
  --hns-c-reserved-nav__item--lg--Color: inherit;
  --hns-c-reserved-nav__item--Color: var(--hns-c-reserved-nav__item--tn--Color);


  /**
   * Navigation switch
   */

  --hns-c-reserved-nav__switch--BlockSize: 4.375rem;  /* 70px */
}

@supports not (row-gap: clamp(1px, 1vh, 1rem)) {
  .hns-c-reserved-nav {
    --hns-c-reserved-nav--lg--RowGap: .875rem;
    --hns-c-reserved-nav--lg--PaddingBlockEnd: 2rem;
    --hns-c-reserved-nav--lg--PaddingBlockStart: 4.875rem;
  }
}

/** Large */
@media all and (min-width: 64rem) {
  .hns-c-reserved-nav {
    --hns-c-reserved-nav--PaddingBlockEnd: var(--hns-c-reserved-nav--lg--PaddingBlockEnd);
    --hns-c-reserved-nav--PaddingBlockStart: var(--hns-c-reserved-nav--lg--PaddingBlockStart);
    --hns-c-reserved-nav--RowGap: var(--hns-c-reserved-nav--lg--RowGap);
    --hns-c-reserved-nav__item--BackgroundColor: var(--hns-c-reserved-nav__item--lg--BackgroundColor);
    --hns-c-reserved-nav__item--Color: var(--hns-c-reserved-nav__item--lg--Color);
  }
}



/**
 * Component
 * 1: contain logout button, navigation switch and border
 */

.hns-c-reserved-nav {
  display: flex;
  flex-direction: column;
  row-gap: var(--hns-c-reserved-nav--RowGap);
  overflow-y: auto;
  padding-block: var(--hns-c-reserved-nav--PaddingBlockStart) var(--hns-c-reserved-nav--PaddingBlockEnd);
  position: relative;  /* 1 */
}

@supports not (padding-block: var(--hns-c-reserved-nav--PaddingBlockStart) var(--hns-c-reserved-nav--PaddingBlockEnd)) {
  .hns-c-reserved-nav {
    padding-bottom: var(--hns-c-reserved-nav--PaddingBlockEnd);
    padding-top: var(--hns-c-reserved-nav--PaddingBlockStart);
  }
}

/**
 * Large
 * 1: center horizontally
 * 2: contain logout button
 */

@media all and (min-width: 64rem) {
  .hns-c-reserved-nav {
    padding-block-end: 0;
  }
}



/**
 * Item list
 * 1: reset
 */

.hns-c-reserved-nav > ul {
  display: contents;
  list-style-type: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

/**
 * Large
 * 1: center horizontally
 * 2: contain logout button
 */

@media all and (min-width: 64rem) {
  .hns-c-reserved-nav > ul {
    block-size: 100%;
    display: inherit;
    flex-direction: inherit;
    margin-inline: auto;  /* 1 */
    padding-block-end: var(--hns-c-reserved-nav--PaddingBlockEnd);
    position: relative;  /* 2 */
    row-gap: inherit;
  }

  @supports not (margin-inline: auto) {
    .hns-c-reserved-nav > ul {
      height: 100%;
      margin-left: auto;  /* 1 */
      margin-right: auto;  /* 1 */
      padding-bottom: var(--hns-c-reserved-nav--PaddingBlockEnd);
    }
  }
}



/**
 * Item
 */

.hns-c-reserved-nav .hns-c-reserved-navitem {
  --hns-c-reserved-navitem--BackgroundColor: var(--hns-c-reserved-nav__item--BackgroundColor);
  --hns-c-reserved-navitem--Color: var(--hns-c-reserved-nav__item--Color);
  flex-grow: 1;
  transform: rotateX(calc((1 - var(--hns-c-reserved-nav--flag)) * 90deg));
  transition: transform var(--hns-c-reserved-nav__item--TransitionDuration) linear calc(var(--hns-c-reserved-navitem--index, 0) * var(--hns-c-reserved-nav__item--TransitionDelay));
}

/** Large */
@media all and (min-width: 64rem) {
  .hns-c-reserved-nav .hns-c-reserved-navitem {
    flex-grow: 0;
    transform: initial;
  }
}



/**
 * Logout button
 */

/** Large */
@media all and (min-width: 64rem) {
  .hns-c-reserved-nav .hns-c-reserved-navitem:last-child {
    inset-block-end: 0;
    inset-inline: 0;
    position: absolute;
    transition: initial;
  }

  @supports not (inset-block-end: 0) {
    .hns-c-reserved-nav .hns-c-reserved-navitem:last-child {
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}



/**
 * Navigation switch
 */

.hns-c-reserved-nav .hns-c-reserved-nav-switch {
  --hns-c-reserved-nav-switch--flag: var(--hns-c-reserved-nav--flag);
  --hns-c-reserved-nav-switch--BlockSize: var(--hns-c-reserved-nav__switch--BlockSize);
  inset: auto 0 0 0;
  position: fixed;
}

@supports not (inset: auto 0 0 0) {
  .hns-c-reserved-nav .hns-c-reserved-nav-switch {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/** Large */
@media all and (min-width: 64rem) {
  .hns-c-reserved-nav .hns-c-reserved-nav-switch {
    display: none;
  }
}

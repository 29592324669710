/** ***************************************************************************
 * Molecules / Modal / Slider
 *************************************************************************** */

@import url("@splidejs/splide/css");

.hns-c-modal.hns-m-slider {
  /**
   * Caption
   */

  --hns-c-modal--m-slider__caption--BackgroundColor: rgba(
    var(--hns-global--palette-dark--100-r),
    var(--hns-global--palette-dark--100-g),
    var(--hns-global--palette-dark--100-b),
    .6
  );
  --hns-c-modal--m-slider__caption--Padding: .2em;
}


/**
 * Slider
 * 1: reset
 * 2: reset list styles
 */

.hns-c-modal.hns-m-slider > .splide {
  width: calc(100vw - var(--hns-c-modal--PaddingBlock));
}

.hns-c-modal.hns-m-slider > .splide ul,
.hns-c-modal.hns-m-slider > .splide li {
  list-style-type: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

.hns-c-modal.hns-m-slider > .splide li::before {
  display: none;  /* 2 */
}



/**
 * Slider image
 * 1: center horizontally
 * 2: reset
 * 3: contain caption
 */

.hns-c-modal.hns-m-slider figure {
  height: calc(100vh - 3 * var(--hns-c-modal--PaddingBlock));
  margin: 0;  /* 2 */
  overflow-x: auto;
  position: relative;  /* 3 */
}

.hns-c-modal.hns-m-slider img {
  block-size: 100%;
  display: block;  /* 1 */
  inline-size: auto;
  margin: auto;  /* 1 */
  max-inline-size: none;  /* 2 */
}

/** Caption */
.hns-c-modal.hns-m-slider figcaption {
  background-color: var(--hns-c-modal--m-slider__caption--BackgroundColor);
  inset: auto 0 0 0;
  padding: var(--hns-c-modal--m-slider__caption--Padding);
  position: absolute;
  text-align: center;
}

@supports not (inline-size: auto) {
  .hns-c-modal.hns-m-slider img {
    height: 100%;
    max-width: none;  /* 2 */
    width: auto;
  }

  .hns-c-modal.hns-m-slider figcaption {
    bottom: 0;
    left: 0;
    right: 0;
  }
}



/**
 * Arrows
 * 1: reset
 */

.hns-c-modal.hns-m-slider button.splide__arrow:focus {
  outline: 0;  /* 1 */
}

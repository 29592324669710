/** ***********************************************************************************
 * Molecules / Language chooser
 *********************************************************************************** */

.hns-c-language-chooser {
  --hns-c-language-chooser--FontFamily: var(--hns-global--FontFamily-normal);
  --hns-c-language-chooser--FontSize: inherit;
  --hns-c-language-chooser--FontWeight: var(--hns-global--FontWeight-bold);
  --hns-c-language-chooser--Gap: .5ch;  /* eyeballed */


  /**
   * Marker
   */

  --hns-c-language-chooser__marker--InsetInline: .2em;
  --hns-c-language-chooser__marker--Opacity: .58;


  /**
   * Divider
   */

  --hns-c-language-chooser__divider--Content: "/";
  --hns-c-language-chooser__divider--Display: block;


  /**
   * Link (inactive language)
   */

  --hns-c-language-chooser__link--FontWeight: var(--hns-global--FontWeight-normal);
}



/**
 * Component
 * 1: reset
 */

.hns-c-language-chooser {
  display: flex;
  flex-direction: row;
  font-family: var(--hns-c-language-chooser--FontFamily);
  font-size: var(--hns-c-language-chooser--FontSize);
  font-weight: var(--hns-c-language-chooser--FontWeight);
  gap: var(--hns-c-language-chooser--Gap);
  list-style: none;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}



/**
 * Item
 * 1: reset
 * 2: contain marker
 */

.hns-c-language-chooser > li {
  display: contents;
  list-style-type: none;  /* 1 */
}

.hns-c-language-chooser > li span {
  position: relative;
}

/**
 * Marker
 * 1: round corners
 * 2: hide for inactive languages
 */

.hns-c-language-chooser > li span::after {
  background-color: currentcolor;
  block-size: 1px;
  border-radius: 100vmax;  /* 1 */
  content: "";
  display: block;
  inset-block: auto 0;
  inset-inline: var(--hns-c-language-chooser__marker--InsetInline);
  opacity: var(--hns-c-language-chooser__marker--Opacity);
  position: absolute;
}

.hns-c-language-chooser > li a > span::after {
  opacity: 0;  /* 2 */
}

@supports not (inset-inline: var(--hns-c-language-chooser__marker--InsetInline)) {
  .hns-c-language-chooser > li span::after {
    bottom: 0;
    height: 1px;
    left: var(--hns-c-language-chooser__marker--InsetInline);
    right: var(--hns-c-language-chooser__marker--InsetInline);
  }
}



/**
 * Divider
 */

.hns-c-language-chooser > :nth-child(n+2)::before {
  content: var(--hns-c-language-chooser__divider--Content);
  display: var(--hns-c-language-chooser__divider--Display);
}



/**
 * Link (inactive language)
 * 1: reset
 */

.hns-c-language-chooser a {
  font-weight: var(--hns-c-language-chooser__link--FontWeight);
  text-decoration: none;  /* 1 */
}

.hns-c-language-chooser a:hover {
  text-decoration: none;
}

/** ***************************************************************************
 * Molecules / Modal / Base
 *************************************************************************** */

@import url("dialog-polyfill/dist/dialog-polyfill.css");

:root {
  --hns-c-modal--BackgroundColor: var(--hns-global--palette-black);
  --hns-c-modal--Color: var(--hns-global--palette-white);
  --hns-c-modal--PaddingBlock: var(--hns-c-modal__close--FontSize);
  --hns-c-modal--PaddingInline: 1rem;


  /**
   * Close button
   */

  --hns-c-modal__close--FontSize: 3rem;
  --hns-c-modal__close--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-modal__close--FontWeight: var(--hns-global--FontWeight-heavy);
  --hns-c-modal__close--Opacity: .7;

  --hns-c-gallery__close--hover--Opacity: .9;
}



/**
 * Component
 * 1: contain close button
 * 2: reset
 * 3: center content
 */

.hns-c-modal[open] {
  background-color: var(--hns-c-modal--BackgroundColor);
  border: 0;  /* 2 */
  color: var(--hns-c-modal--Color);
  display: grid;  /* 3 */
  height: 100vh;
  margin: 0;  /* 2 */
  padding-block: var(--hns-c-modal--PaddingBlock);
  padding-inline: var(--hns-c-modal--PaddingInline);
  place-items: center;  /* 3 */
  position: fixed;  /* 1 */
  width: 100vw;
}

@supports not (padding-block: var(--hns-c-modal--PaddingBlock)) {
  .hns-c-modal {
    padding-bottom: var(--hns-c-modal--PaddingBlock);
    padding-left: var(--hns-c-modal--PaddingInline);
    padding-right: var(--hns-c-modal--PaddingInline);
    padding-top: var(--hns-c-modal--PaddingBlock);
  }
}



/**
 * Backdrop
 * 1: can't use a custom property here. why?
 * 2: see https://github.com/GoogleChrome/dialog-polyfill#backdrop
 */

.hns-c-modal::backdrop {
  /* background-color: var(--hns-c-modal--BackgroundColor); */
  background-color: #000;  /* 1 */
}


.hns-c-modal + .backdrop {  /* 2 */
  /* background-color: var(--hns-c-modal--BackgroundColor); */
  background-color: #000;  /* 1 */
}



/**
 * Close button
 * 1: reset
 */

.hns-c-modal > button {
  appearance: none;  /* 1 */
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  color: currentcolor;
  cursor: pointer;
  font-family: var(--hns-c-modal__close--FontFamily);
  font-size: var(--hns-c-modal__close--FontSize);
  font-weight: var(--hns-c-modal__close--FontWeight);
  line-height: .5;
  margin: 0;  /* 1 */
  opacity: var(--hns-c-modal__close--Opacity);
  padding: 0;  /* 1 */
  position: absolute;
  right: var(--hns-c-modal--PaddingInline);
  top: var(--hns-c-modal--PaddingInline);
}

.hns-c-modal > button:hover,
.hns-c-modal > button:focus {
  opacity: var(--hns-c-gallery__close--hover--Opacity);
}

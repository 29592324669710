/** ***************************************************************************
 * Crag card
 *************************************************************************** */

:root {

  --hns-c-crag-card__left-cut--Width: 0px;  /* needs a unit */
  --hns-c-crag-card__right-cut--Width: 0px;  /* needs a unit */

  --hns-c-crag-card--BackgroundColor: var(--hns-global--palette-blue--40);
  --hns-c-crag-card--BackgroundImage: initial;
  --hns-c-crag-card--Color: var(--hns-global--palette-white);
  --hns-c-crag-card--FontFamily: var(--hns-global--FontFamily-normal);
  --hns-c-crag-card--FontSize: var(--hns-global--FontSize-paragraph--md);
  --hns-c-crag-card--FontWeight: var(--hns-global--FontWeight-regular);
  --hns-c-crag-card--PaddingBlockEnd: 2.625rem;  /* 42px */
  --hns-c-crag-card--PaddingBlockStart: 1.125rem;  /* 20px */
  --hns-c-crag-card--PaddingInlineEnd: var(--hns-c-crag-card--PaddingBlockEnd);
  --hns-c-crag-card--PaddingInlineStart: var(--hns-c-crag-card--PaddingBlockEnd);
  --hns-c-crag-card--TransitionDuration: var(--hns-global--Duration--sm);

  --hns-c-crag-card--hover--Transform: scale(1.02);

  /**
   * Heading
   */

  --hns-c-crag-card__heading--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-crag-card__heading--FontWeight: var(--hns-global--FontWeight-heavy);
  --hns-c-crag-card__heading--TransitionDuration: var(--hns-global--Duration--sm);
  --hns-c-crag-card__heading--TransitionTimingFunction: var(--hns-global--AnimationTimingFunction--bounce);

  --hns-c-crag-card__heading--tn--FontSize: var(--hns-global--FontSize-title--2xl);
  --hns-c-crag-card__heading--sm--FontSize: var(--hns-global--FontSize-title--md);
  --hns-c-crag-card__heading--md--FontSize: var(--hns-global--FontSize-title--2xl);
  --hns-c-crag-card__heading--FontSize: var(--hns-c-crag-card__heading--tn--FontSize);


  /**
   * Label
   */

  --hns-c-crag-card__label--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-crag-card__label--FontSize: var(--hns-global--FontSize-title--xs);
  --hns-c-crag-card__label--FontWeight: var(--hns-global--FontWeight-heavy);
}

/**
 * Small
 */

@media all and (min-width: 34rem) {
  :root {
    --hns-c-crag-card__heading--FontSize: var(--hns-c-crag-card__heading--sm--FontSize);
  }
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  :root {
    --hns-c-crag-card__heading--FontSize: var(--hns-c-crag-card__heading--md--FontSize);
  }
}



/**
 * Component
 * 1: reset
 * 2: contain link
 */

.hns-c-crag-card {
  background-blend-mode: multiply;
  background-color: var(--hns-c-crag-card--BackgroundColor);
  background-image: var(--hns-c-crag-card--BackgroundImage);
  background-size: cover;
  color: var(--hns-c-crag-card--Color);
  display: grid;
  font-family: var(--hns-c-crag-card--FontFamily);
  font-size: var(--hns-c-crag-card--FontSize);
  font-weight: var(--hns-c-crag-card--FontWeight);
  grid:
    "subtitle" auto
    "title"    1fr
    "content"    auto;
  padding-block: var(--hns-c-crag-card--PaddingBlockStart) var(--hns-c-crag-card--PaddingBlockEnd);
  padding-inline-end: calc(var(--hns-c-crag-card--PaddingInlineEnd) + var(--hns-c-crag-card__right-cut--Width));
  padding-inline-start: calc(var(--hns-c-crag-card--PaddingInlineStart) + var(--hns-c-crag-card__left-cut--Width));
  position: relative;  /* 2 */
  transition: transform var(--hns-c-crag-card--TransitionDuration) linear;
}

.hns-c-crag-card:hover {
  transform: var(--hns-c-crag-card--hover--Transform);
}

.hns-c-crag-card > * {
  margin: 0;  /* 1 */
}

@supports not (padding-block: var(--hns-c-crag-card--PaddingBlockStart) / var(--hns-c-crag-card--PaddingBlockEnd)) {
  .hns-c-crag-card {
    padding-bottom: var(--hns-c-crag-card--PaddingBlockEnd);
    padding-left: calc(var(--hns-c-crag-card--PaddingInlineStart) + var(--hns-c-crag-card__left-cut--Width));
    padding-right: calc(var(--hns-c-crag-card--PaddingInlineEnd) + var(--hns-c-crag-card__right-cut--Width));
    padding-top: var(--hns-c-crag-card--PaddingBlockStart);
  }
}


/**
 * Heading
 * 1: Safari needs a hint
 */

.hns-c-crag-card > :first-child {
  font-family: var(--hns-c-crag-card__heading--FontFamily);
  font-size: var(--hns-c-crag-card__heading--FontSize);
  font-weight: var(--hns-c-crag-card__heading--FontWeight);
  min-height: 2em;  /* 1 */
  transition: font-size var(--hns-c-crag-card__heading--TransitionDuration) var(--hns-c-crag-card__heading--TransitionTimingFunction);
}



/**
 * Link
 */

.hns-c-crag-card > :first-child a {
  text-decoration-thickness: 1px;
  text-decoration: none;
}

.hns-c-crag-card > :first-child a:hover {
  text-decoration: underline;
}



/**
 * Subtitle (parent)
 */

.hns-c-crag-card__subtitle {
  grid-area: subtitle;
}



/**
 * Content
 */

.hns-c-crag-card dl {
  grid-area: content;
}

.hns-c-crag-card dt,
.hns-c-crag-card dd {
  display: inline-block;
  margin: 0;
}


/**
 * Label
 */

.hns-c-crag-card dd {
  font-family: var(--hns-c-crag-card__label--FontFamily);
  font-size: var(--hns-c-crag-card__label--FontSize);
  font-weight: var(--hns-c-crag-card__label--FontWeight);
}

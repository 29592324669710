/** ***************************************************************************
 * Atoms / Reserved area / Navigation item
 *************************************************************************** */

.hns-c-reserved-navitem {
  --hns-c-reserved-navitem--BackgroundColor: transparent;
  --hns-c-reserved-navitem--Color: inherit;
  --hns-c-reserved-navitem--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-label--sm) var(--hns-global--FontFamily-normal);
  --hns-c-reserved-navitem--PaddingBlock: 1.5em;  /* 24px */
  --hns-c-reserved-navitem--Gap: 1rem;  /* 15px */

  --hns-c-reserved-navitem--tn--PaddingBlock: .2em;  /* eyeballed */
  --hns-c-reserved-navitem--lg--PaddingBlock: 0;
  --hns-c-reserved-navitem--PaddingBlock: var(--hns-c-reserved-navitem--tn--PaddingBlock);

  --hns-c-reserved-navitem--tn--PaddingInline: calc(var(--hns-global--content-grid--column-width) + var(--hns-global--content-grid--gutter-width));
  --hns-c-reserved-navitem--lg--PaddingInline: 0;
  --hns-c-reserved-navitem--PaddingInline: var(--hns-c-reserved-navitem--tn--PaddingInline);


  /**
   * Indicator
   */

  --hns-c-reserved-navitem__indicator--InlineSize: 3px;
  --hns-c-reserved-navitem__indicator--TransitionDuration: var(--hns-global--Duration--xs);

  --hns-c-reserved-navitem__indicator--tn--BlockSize: 1.25em;  /* 21px */
  --hns-c-reserved-navitem__indicator--lg--BlockSize: 1.75em;  /* 29px */
  --hns-c-reserved-navitem__indicator--BlockSize: var(--hns-c-reserved-navitem__indicator--tn--BlockSize);

  --hns-c-reserved-navitem--hover__indicator--Opacity: .31;
  --hns-c-reserved-navitem--m-active__indicator--Opacity: 1;
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-reserved-navitem {
    --hns-c-reserved-navitem--PaddingBlock: var(--hns-c-reserved-navitem--lg--PaddingBlock);
    --hns-c-reserved-navitem--PaddingInline: var(--hns-c-reserved-navitem--lg--PaddingInline);
    --hns-c-reserved-navitem__indicator--BlockSize: var(--hns-c-reserved-navitem__indicator--lg--BlockSize);
  }
}



/**
 * Component
 * 1: reset
 * 2: contain link
 */

.hns-c-reserved-navitem {
  align-items: center;
  background-color: var(--hns-c-reserved-navitem--BackgroundColor);
  color: var(--hns-c-reserved-navitem--Color);
  display: flex;
  flex-direction: row;
  font: var(--hns-c-reserved-navitem--Font);
  gap: var(--hns-c-reserved-navitem--Gap);
  padding-block: var(--hns-c-reserved-navitem--PaddingBlock);
  padding-inline: var(--hns-c-reserved-navitem--PaddingInline);
  position: relative;  /* 2 */
}

@supports not (padding-block: var(--hns-c-reserved-navitem--PaddingBlock)) {
  .hns-c-reserved-navitem {
    padding-bottom: var(--hns-c-reserved-navitem--PaddingBlock);
    padding-top: var(--hns-c-reserved-navitem--PaddingBlock);
  }
}



/**
 * Link
 */

.hns-c-reserved-navitem  a {
  color: inherit;  /* 1 */
  text-decoration: none;  /* 1 */
}



/**
 * Indicator
 * 1: round corners
 */

.hns-c-reserved-navitem::before {
  background-color: currentcolor;
  block-size: var(--hns-c-reserved-navitem__indicator--BlockSize);
  border-radius: 100vmax;  /* 1 */
  content: "";
  display: block;
  inline-size: var(--hns-c-reserved-navitem__indicator--InlineSize);
  opacity: 0;
  transition: opacity var(--hns-c-reserved-navitem__indicator--TransitionDuration) linear;
}

.hns-c-reserved-navitem:hover::before {
  opacity: var(--hns-c-reserved-navitem--hover__indicator--Opacity);
}

.hns-c-reserved-navitem.hns-m-selected::before {
  opacity: var(--hns-c-reserved-navitem--m-active__indicator--Opacity);
}

@supports not (inline-size: var(--hns-c-reserved-navitem__indicator--InlineSize)) {
  .hns-c-reserved-navitem::before {
    height: var(--hns-c-reserved-navitem__indicator--BlockSize);
    width: var(--hns-c-reserved-navitem__indicator--InlineSize);
  }
}

/** ***********************************************************************************
 * Leaflet map
 *********************************************************************************** */

:root {
  --hns-c-map__popup--FontFamily: var(--hns-global--FontFamily-normal);
}



/**
 * Popup
 */

.hns-c-map .leaflet-popup-content {
  font-family: var(--hns-c-map__popup--FontFamily);
}

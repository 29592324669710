/** ***************************************************************************
 * Atoms / Status
 *************************************************************************** */

.hns-c-status {
  --hns-c-status--Font: var(--hns-global--FontWeight-bold) var(--hns-global--FontSize-label--sm) var(--hns-global--FontFamily-normal);
  --hns-c-status--Gap: .875em;  /* 14px */


  /**
   * Icon
   */

  --hns-c-status__icon--BlockSize: 1.875em;  /* 30px */
}



/**
 * Component
 * 1: see Icon (1)
 */

.hns-c-status {
  /* gap: var(--hns-c-status--Gap); */  /* 1 */
  align-items: center;
  display: flex;
  flex-direction: row;
  font: var(--hns-c-status--Font);
}




/**
 * Icon
 * 1: simulate gap. see Component (1)
 */

.hns-c-status svg {
  block-size: var(--hns-c-status__icon--BlockSize);
  margin-inline-end: var(--hns-c-status--Gap);  /* 1 */
}

@supports not (block-size: var(--hns-c-status__icon--BlockSize)) {
  .hns-c-status svg {
    height: var(--hns-c-status__icon--BlockSize);
    margin-right: var(--hns-c-status--Gap);  /* 1 */
  }
}

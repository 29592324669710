/** ***************************************************************************
 * Atoms / List
 *************************************************************************** */

:root {
  /**
   * List
   */

  --hns-c-list--MarginBlock: 2em;
  --hns-c-list--MarginInline: 0;


  /**
   * List item
   */

  --hns-c-list__item--MarginBlockStart: 1.5rem;  /* 24px */
  --hns-c-list__item--PaddingBlockStart: .5rem;  /* 8px */
  --hns-c-list__item--PaddingInlineStart: 2.5rem;  /* 40px */

  /**
   * List item label
   */

  --hns-c-list__item-label--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-list__item-label--FontSize: var(--hns-global--FontSize-subtitle--sm);
  --hns-c-list__item-label--MarginBlockStart: -0.5rem;  /* -8px */


  /**
   * List Marker
   */

  --hns-c-list-marker--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-list-marker--FontSize: var(--hns-global--FontSize-subtitle--sm);

}



main ul,
main ol {
  counter-reset: list;
  list-style-type: none;
  margin-block: var(--hns-c-list--MarginBlock);
  margin-inline: var(--hns-c-list--MarginInline);
  padding: 0;
}

@supports not (margin-block: var(--hns-c-list--MarginBlock)) {
  main ul,
  main ol {
    margin: var(--hns-c-list--MarginBlock) var(--hns-c-list--MarginInline);
  }
}



/**
 * List item
 * 1: contain marker
 * 2: make space for marker
 */

main ul > li,
main ol > li {
  padding-inline-start: var(--hns-c-list__item--PaddingInlineStart);  /* 2 */
  position: relative;  /* 1 */
}

main ul > li:nth-of-type(n+2),
main ol > li:nth-of-type(n+2) {
  margin-block-start: var(--hns-c-list__item--MarginBlockStart);
  padding-block-start: var(--hns-c-list__item--PaddingBlockStart);
}

@supports not (margin-block-start: var(--hns-c-list__item--MarginBlockStart)) {
  main ul > li,
  main ol > li {
    padding-left: var(--hns-c-list__item--PaddingInlineStart);
  }

  main ul > li:nth-of-type(n+2),
  main ol > li:nth-of-type(n+2) {
    margin-top: var(--hns-c-list__item--MarginBlockStart);
    padding-left: var(--hns-c-list__item--PaddingInlineStart);
    padding-top: var(--hns-c-list__item--PaddingBlockStart);
  }
}


/**
 * List item label
 */

main ul > li > strong:first-child,
main ol > li > strong:first-child {
  display: block;
  font-family: var(--hns-c-list__item-label--FontFamily);
  font-size: var(--hns-c-list__item-label--FontSize);
  margin-block-start: var(--hns-c-list__item-label--MarginBlockStart);
}

@supports not (margin-block-start: var(--hns-c-list__item-label--MarginBlockStart)) {
  main ul > li > strong:first-child,
  main ol > li > strong:first-child {
    margin-top: var(--hns-c-list__item-label--MarginBlockStart);
  }
}



/**
 * List marker
 */

main ul > li::before,
main ol > li::before {
  display: inline-block;
  font-family: var(--hns-c-list-marker--FontFamily);
  font-size: var(--hns-c-list-marker--FontSize);
  left: 0;
  position: absolute;
  top: 0;
}

main ul > li::before {
  content: "◼︎"; /* \25FC */
}

main ol > li::before {
  content: counter(list) ".";
  counter-increment: list;
}

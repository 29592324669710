/* breadcrumb */

  .breadcrumb ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .breadcrumb li {
    display: inline-block;
  }

  .breadcrumb li:nth-child(n+2)::before {
    content: "/";
    display: inline;
  }

/** ***************************************************************************
 * Atoms / Forms / Error
 *************************************************************************** */


/**
 * Component
 * 1: reset list styles
 */

.hns-c-form-error {
  --hns-c-form-error--Color: var(--hns-global--palette-red);

  color: var(--hns-c-form-error--Color);
  padding-inline-start: 0;  /* 1 */
}


.hns-c-form-error::before {
  display: none;  /* 1 */
}

/** ***************************************************************************
 * Charlie
 *************************************************************************** */

:root {
  --hns-c-charlie--BackgroundColor-r: var(--hns-c-page--BackgroundColor-r);
  --hns-c-charlie--BackgroundColor-g: var(--hns-c-page--BackgroundColor-g);
  --hns-c-charlie--BackgroundColor-b: var(--hns-c-page--BackgroundColor-b);
  --hns-c-charlie--BackgroundColor-a: var(--hns-c-page--BackgroundColor-a);

  --hns-c-charlie--Color-r: var(--hns-c-page--Color-r);
  --hns-c-charlie--Color-g: var(--hns-c-page--Color-g);
  --hns-c-charlie--Color-b: var(--hns-c-page--Color-b);
  --hns-c-charlie--Color-a: var(--hns-c-page--Color-a);

  --hns-c-charlie--FontSize: var(--hns-global--FontSize-paragraph--md);


  /**
   * Header
   */

  --hns-c-charlie__header--Gap: 2rem;  /* 32px */
  --hns-c-charlie__header--PaddingBlock: 5.2rem;  /* 83px */
  --hns-c-charlie__header--TransitionDuration: var(--hns-global--Duration--sm);

  --hns-c-charlie__header--tn--PaddingInline: 0;
  --hns-c-charlie__header--md--PaddingInline: var(--hns-c-charlie__header--PaddingBlock);
  --hns-c-charlie__header--lg--PaddingInline: 2rem;
  --hns-c-charlie__header--PaddingInline: var(--hns-c-charlie__header--tn--PaddingInline);


  /**
   * Heading (button)
   */

  --hns-c-charlie__heading--FontFamily: var(--hns-global--FontFamily-normal);
  --hns-c-charlie__heading--FontSize: var(--hns-global--FontSize-title--2xs);
  --hns-c-charlie__heading--FontWeight: var(--hns-global--FontWeight-bold);
  --hns-c-charlie__heading--hover--Transform: scale(1.1);


  /**
   * Label
   */

  --hns-c-charlie__label--hover--Transform: scale(1.2);


  /**
   * Icon
   */

  --hns-c-charlie__icon--AspectRatio: 1 / 1;  /* square */
  --hns-c-charlie__icon--Width: 6rem;  /* 93px */


  /**
   * Content
   */

  --hns-c-charlie__content--Column: var(--column-content-start) / var(--column-content-end);
  --hns-c-charlie__content--TemplateColumns: var(--columns-default);


  /**
   * First content-child
   */

  --hns-c-charlie__content-first--tn--MarginBlockStart: inherit;
  --hns-c-charlie__content-first--md--MarginBlockStart: 6.25rem; /* 100px */
  --hns-c-charlie__content-first--MarginBlockStart: var(--hns-c-charlie__content-first--tn--MarginBlockStart);


  /**
   * Sectioning
   */

  --hns-c-charlie__sectioning--tn--MarginBlockStart: inherit;
  --hns-c-charlie__sectioning--lg--MarginBlockStart: 0;
  --hns-c-charlie__sectioning--MarginBlockStart: var(--hns-c-charlie__sectioning--tn--MarginBlockStart);

  --hns-c-charlie__sectioning--tn--PaddingBlockStart: 0;
  --hns-c-charlie__sectioning--lg--PaddingBlockStart: calc(4.54rem + 6.25rem);  /* rocks + 100px */
  --hns-c-charlie__sectioning--PaddingBlockStart: var(--hns-c-charlie__sectioning--tn--PaddingBlockStart);


  /**
   * Content spacer
   */

  --hns-c-charlie__spacer--tn--Height: 5rem;  /* 80px */
  --hns-c-charlie__spacer--lg--Height: 8.75rem; /* 141px */
  --hns-c-charlie__spacer--Height: var(--hns-c-charlie__spacer--tn--Height);
}

/**
 * Medium
 */

@media (min-width: 48rem) {
  :root {
    --hns-c-charlie__content-first--MarginBlockStart: var(--hns-c-charlie__content-first--md--MarginBlockStart);
    --hns-c-charlie__header--PaddingInline: var(--hns-c-charlie__header--md--PaddingInline);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  :root {
    --hns-c-charlie__sectioning--MarginBlockStart: var(--hns-c-charlie__sectioning--lg--MarginBlockStart);
    --hns-c-charlie__sectioning--PaddingBlockStart: var(--hns-c-charlie__sectioning--lg--PaddingBlockStart);
    --hns-c-charlie__spacer--Height: var(--hns-c-charlie__spacer--lg--Height);
    --hns-c-charlie__header--PaddingInline: var(--hns-c-charlie__header--lg--PaddingInline);
  }
}



/**
 * Component
 * 1: set color palette
 * 2: disable button animation
 */

.hns-c-charlie {
  --hns-c-charlie--BackgroundColor: rgba(  /* 1 */
    var(--hns-c-charlie--BackgroundColor-r),
    var(--hns-c-charlie--BackgroundColor-g),
    var(--hns-c-charlie--BackgroundColor-b),
    var(--hns-c-charlie--BackgroundColor-a)
  );
  --hns-c-charlie--Color: rgba(  /* 1 */
    var(--hns-c-charlie--Color-r),
    var(--hns-c-charlie--Color-g),
    var(--hns-c-charlie--Color-b),
    var(--hns-c-charlie--Color-a)
  );
  --hns-u-button__border--hover--Transform: initial;  /* 2 */
  --hns-c-sectioning--PaddingBlockStart: var(--hns-c-charlie__sectioning--PaddingBlockStart);

  font-size: var(--hns-c-charlie--FontSize);
}

.hns-c-charlie > header,
.hns-c-charlie > div {
  background-color: var(--hns-c-charlie--BackgroundColor);
  color: var(--hns-c-charlie--Color);
}



/**
 * Header
 * 1: reset
 * 2: contain label
 */

.hns-c-charlie > header {
  align-items: center;
  display: grid;
  grid-gap: var(--hns-c-charlie__header--Gap);
  grid:
    ".                      .       icon                              .       ."
    ".                      heading heading                           heading ." 1fr
  / var(--margin-left, 1fr) auto    var(--hns-c-charlie__icon--Width) auto    var(--margin-right, 1fr);
  justify-items: center;
  padding-block: var(--hns-c-charlie__header--PaddingBlock);
  padding-inline: var(--hns-c-charlie__header--PaddingInline);
  position: relative;  /* 2 */
}

.hns-c-charlie > header > * {
  margin: 0;  /* 1 */
}

.hns-c-charlie[data-status] > header {
  cursor: pointer;
}

@supports not (padding-block: var(--hns-c-charlie__header--PaddingBlock)) {
  .hns-c-charlie > header {
    padding: var(--hns-c-charlie__header--PaddingBlock) var(--hns-c-charlie__header--PaddingInline);
  }
}



/**
 * Heading (button)
 */

.hns-c-charlie > header > :first-child {
  align-self: start;
  font-family: var(--hns-c-charlie__heading--FontFamily);
  font-size: var(--hns-c-charlie__heading--FontSize);
  font-weight: var(--hns-c-charlie__heading--FontWeight);
  grid-area: heading;
  text-align: center;
  text-transform: initial;
}

@media (hover: hover) {
  .hns-c-charlie > header > :first-child {
    transition: transform var(--hns-c-charlie__header--TransitionDuration) ease-in-out;
  }

  .hns-c-charlie[data-status] > header:hover > :first-child,
  .hns-c-charlie[data-status] > header:focus-within > :first-child {
    transform: var(--hns-c-charlie__heading--hover--Transform);
  }
}



/**
 * Label
 * 1: reset
 */

.hns-c-charlie > header > label {
  grid-area: icon;
}

.hns-c-charlie > header > label:focus {
  outline: none;  /* 1 */
}

@media (hover: hover) {
  .hns-c-charlie > header > label {
    transition: transform var(--hns-c-charlie__header--TransitionDuration) ease-in-out;
  }

  .hns-c-charlie[data-status] > header:hover > label,
  .hns-c-charlie[data-status] > header:focus-within > label {
    transform: var(--hns-c-charlie__label--hover--Transform);
  }
}



/**
 * Icon
 */

.hns-c-charlie > header img,
.hns-c-charlie > header svg {
  /*height: 6rem;*/ /* 93px */
  width: var(--hns-c-charlie__icon--Width); /* 93px */
  aspect-ratio: var(--hns-c-charlie__icon--AspectRatio);
}

@supports not (aspect-ratio: var(--hns-c-charlie__icon--AspectRatio)) {
  .hns-c-charlie > header img,
  .hns-c-charlie > header svg {
    height: calc(var(--hns-c-charlie__icon--Width) / (var(--hns-c-charlie__icon--AspectRatio)))
  }
}



/**
 * Content
 */

.hns-c-charlie > div {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: var(--hns-c-charlie__content--TemplateColumns);
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--hns-global--Duration--sm) linear;
}

.hns-c-charlie > .hns-c-toggle:checked + div {
  max-height: var(--height-content, none);
}

.hns-c-charlie > div > * {
  grid-column: var(--hns-c-charlie__content--Column);
  overflow-y: hidden;
}



/**
 * First content-child
 */

.hns-c-charlie > div > :first-child {
  margin-block-start: var(--hns-c-charlie__content-first--MarginBlockStart);
}

@supports not (margin-block-start: var(--hns-c-charlie__content-first--MarginBlockStart)) {
  .hns-c-charlie > div > :first-child {
    margin-top: var(--hns-c-charlie__content-first--MarginBlockStart);
  }
}



/**
 * Form
 * 1: exchange background and foreground colors
 */

.hns-c-charlie > div > form {
  background-color: var(--hns-c-charlie--Color);
  color: var(--hns-c-charlie--BackgroundColor);
}



/**
 * Sectioning
 */

.hns-c-charlie > div > .hns-c-sectioning:first-child {
  margin-block-start: var(--hns-c-charlie__sectioning--MarginBlockStart);
}

@supports not (margin-block-start: var(--hns-c-charlie__sectioning--MarginBlockStart)) {
  .hns-c-charlie > div > .hns-c-sectioning:first-child {
    margin-top: var(--hns-c-charlie__sectioning--MarginBlockStart);
  }
}



/**
 * Content spacer
 */

.hns-c-charlie > div::after {
  content: "";
  display: block;
  grid-column: viewport-start / viewport-end;
  height: var(--hns-c-charlie__spacer--Height);
}


/**
 * Old code
 */

    .hns-c-charlie > div > p:nth-of-type(n+2) {
      margin-top: 1em;
    }

    .hns-c-charlie > div > .hns-c-sectioning,
    .hns-c-charlie > div > img {
      grid-column: viewport-start / viewport-end;
    }

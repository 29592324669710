/** ***************************************************************************
 * Organisms / CMS plugins / Call to action
 *************************************************************************** */

:root {
  /**
   * Button
   */

  --hns-c-cta__button--tn--FontSize: var(--hns-global--FontSize-button--sm);
  --hns-c-cta__button--lg--FontSize: var(--hns-global--FontSize-button--lg);
  --hns-c-cta__button--FontSize: var(--hns-c-cta__button--tn--FontSize);

  --hns-c-cta--m-aligned__button--FontSize: var(--hns-global--FontSize-button--lg);;
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  :root {
    --hns-c-cta__button--FontSize: var(--hns-c-cta__button--lg--FontSize);
  }
}



/**
 * Component
 */

.hns-c-cta {
  --hns-c-button--FontSize: var(--hns-c-cta__button--FontSize);
}

.hns-c-cta.align-left > .hns-c-button,
.hns-c-cta.align-right > .hns-c-button {
  --hns-c-button--FontSize: var(--hns-c-cta--m-aligned__button--FontSize);
}


/* Old code */

  /* typography */

    .hns-c-cta {
      font-family: var(--hns-global--FontFamily-normal);
      font-size: var(--hns-global--FontSize-paragraph--sm);
      font-weight: var(--hns-global--FontWeight-bold);
      text-align: center;
    }

    .hns-c-cta > h1,
    .hns-c-cta > h2,
    .hns-c-cta > h3,
    .hns-c-cta > h4,
    .hns-c-cta > h5,
    .hns-c-cta > h6 {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--2xl);
      font-weight: var(--hns-global--FontWeight-heavy);
    }

    /*
     * md
     *   enlarge the content font size for left and right aligned variants
     *   enlarge the heading font size for left and right aligned variants
     *   the text alignment changes to `left` for the center aligned variant
     */
    @media (min-width: 48rem) {
      .hns-c-cta.align-center {
        text-align: left;
      }

      .hns-c-cta.align-left,
      .hns-c-cta.align-right {
        font-size: var(--hns-global--FontSize-paragraph--md);
      }

      /* heading */
      .hns-c-cta.align-left > :first-child,
      .hns-c-cta.align-right > :first-child {
        font-size: var(--hns-global--FontSize-title--4xl);
      }
    }

    /*
     * lg
     *   the content font size is the same for all variants
     *   the heading font size is the same for all variants
     *   the button font size is the same for all variants
     */
    @media (min-width: 64rem) {
      .hns-c-cta {
        font-size: var(--hns-global--FontSize-paragraph--md);
      }

      .hns-c-cta > h1,
      .hns-c-cta > h2,
      .hns-c-cta > h3,
      .hns-c-cta > h4,
      .hns-c-cta > h5,
      .hns-c-cta > h6 {
        font-size: var(--hns-global--FontSize-title--4xl);
      }

    }

    /*
     * hg
     *  the text alignment is the same for everyone
     */
    @media (min-width: 90rem) {
      .hns-c-cta {
        text-align: left;
      }
    }

  /* colors */

    .hns-c-cta {
      --color-mask:
        rgba(
          var(--color-background-r),
          var(--color-background-g),
          var(--color-background-b),
          .4
        );
      --color-foreground: var(--hns-global--palette-white);
      color: var(--color-foreground);
    }

    .hns-c-cta > svg {
      color: rgba(
        var(--hns-global--palette-blue--100-r),
        var(--hns-global--palette-blue--100-g),
        var(--hns-global--palette-blue--100-b),
        .5
      );
    }

  /* layout and colors */

    .hns-c-cta {
      --column-content-end: -4;
      --column-content-start: 4;
      --column-content: var(--column-content-start) / var(--column-content-end);
      --margin-after: 2.25rem;        /* 37px */
      --margin-before: 3.5rem;        /* 58px */
      --margin-content-after: .75rem; /* 11px */
      --margin-content-before: 1rem;  /* 16px */
      display: grid;
      grid-template-columns: var(--columns-default);
      grid-template-rows:
        [heading]
        auto
        var(--margin-content-before)
        [content]
        auto
        var(--margin-content-after)
        [button]
        auto;
      padding-bottom: var(--margin-after);
      padding-top: var(--margin-before);
      position: relative;
    }

    .hns-c-cta::before {
      background-size: cover;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .hns-c-cta > * {
      z-index: 1;
    }

    .hns-c-cta.align-left,
    .hns-c-cta.align-right {
      --margin-after: var(--margin-before); /* 121px */
      --margin-before: 7.5rem;              /* 120px */
      --margin-content-after: 1rem;         /*  16px */
      --margin-content-before: 1.5rem;      /*  24px */
    }

    .hns-c-cta.align-left::before,
    .hns-c-cta.align-right::before {
      background-image: var(--image-background);
      background-blend-mode: multiply;
      background-color: var(--color-mask);
      background-position: center;
    }

    .hns-c-cta.align-center::before {
      background-image:
        linear-gradient(
          to top,
          rgba(
            var(--color-background-r),
            var(--color-background-g),
            var(--color-background-b),
            .83) 0,
          rgba(
            var(--color-background-r),
            var(--color-background-g),
            var(--color-background-b),
            .11) 100%
        ), var(--image-background);
      background-position: top left, center;
    }

    .hns-c-cta > * {
      grid-column: var(--column-content);
      grid-row-end: span 1;
      margin: 0;
    }

    .hns-c-cta > svg {
      display: none;
    }

    .hns-c-cta > h1,
    .hns-c-cta > h2,
    .hns-c-cta > h3,
    .hns-c-cta > h4,
    .hns-c-cta > h5,
    .hns-c-cta > h6 {
      grid-row-start: heading;
    }

    .hns-c-cta > div {
      grid-row-start: content;
    }

    .hns-c-cta > div > :first-child {
      margin-top: 0;
    }

    .hns-c-cta > div > :last-child {
      margin-bottom: 0;
    }

    .hns-c-cta > .hns-c-button {
      grid-row-start: button;
      justify-self: center;
    }

    /*
     * md
     *   review spacings
     *   on the center aligned variant the button goes left
     */
    @media (min-width: 48rem) {
      .hns-c-cta {
        --column-content-end: -5;
        --column-content-start: 5;
        --margin-after: 5.25rem;          /* 83px */
        --margin-before: 10.75rem;        /* 173px */
        --margin-content-after: 1.5rem;   /*  24px */
        --margin-content-before: 1.25rem; /*  21px */
      }

      .hns-c-cta.align-left,
      .hns-c-cta.align-right {
        --column-content-end: -7;
        --column-content-start: 7;
        --margin-after: 6.25rem;         /* 101px */
        --margin-before: 13.5rem;        /* 216px */
        --margin-content-after: 2rem;    /*  32px */
        --margin-content-before: 2.5rem; /*  40px */
      }

      .hns-c-cta > .hns-c-button {
        justify-self: start;
      }

      .hns-c-cta.align-left > .hns-c-button,
      .hns-c-cta.align-right > .hns-c-button {
        justify-self: center;
      }
    }

    /*
     * lg
     *   again: review spacings
     */
    @media (min-width: 64rem) {
      .hns-c-cta {
        --column-content-end: -4;
        --column-content-start: 4;
        --margin-after: 5.75rem;       /*  93px */
        --margin-before: 16.25rem;     /* 262px */
        --margin-content-after: 0;     /*  24px */
        --margin-content-before: 2rem; /*  32px */
      }

      .hns-c-cta.align-center {
        --column-button-start: -9;
        --column-button: var(--column-button-start) / var(--column-content-end);
        grid-template-rows:
          [heading button]
          auto
          var(--margin-content-before)
          [content]
          auto;
      }

      .hns-c-cta.align-center > .hns-c-button {
        align-self: end;
        grid-column: var(--column-button);
        justify-self: stretch;
      }

      .hns-c-cta.align-center > :first-child {
        grid-column: var(--column-content-start) / calc(var(--column-button-start) - 1);
      }

      .hns-c-cta.align-left,
      .hns-c-cta.align-right {
        --column-content-end: -5;
        --column-content-start: 5;
        --column-count: 10;
        --columns-default:
          [content-start]
          repeat(
            var(--column-count),
            var(--width-gutter) var(--width-column)
          )
          var(--width-gutter)
          [content-end];
      }
    }

    /*
     * hg
     *   here come the rocks
     */
    @media (min-width: 90rem) {
      .hns-c-cta > svg {
        display: block;
        grid-column: initial;
        height: calc(var(--width-rock) * var(--ratio-height-rock));
        position: absolute;
        transform: translate(
          calc(var(--dx, 0px) + var(--scrolled, 0) * var(--ddx, 0px)),
          calc(var(--dy, 0px) + var(--scrolled, 0) * var(--ddy, 0px))
        ) rotateZ(calc(var(--dg, 0deg) + var(--scrolled, 0) * var(--ddg, 0deg)));
        width: var(--width-rock);
        will-change: transform;
        transition: transform .1s linear;
      }

      .hns-c-cta.index-0 > svg {
        --ddg: 6deg;
        --dg: -157deg;
        --width-rock: 12.5rem; /* 201px */
        right: calc(-5 * var(--hns-global--content-grid--column-width) - 3 * var(--hns-global--content-grid--gutter-width));
        top: 16rem;
      }

      .hns-c-cta.index-1 > svg:nth-of-type(1) {
        --ddg: -4deg;
        --dg: -225deg;
        --width-rock: 12.5rem; /* 201px */
        left: calc(-2 * var(--hns-global--content-grid--column-width) - 2 * var(--hns-global--content-grid--gutter-width) - 7rem);
        top: -2rem;
      }

      .hns-c-cta.index-1 > svg:nth-of-type(2) {
        --dg: -15deg;
        --width-rock: 7rem; /* 111px */
        right: calc(-3 * var(--hns-global--content-grid--column-width) - 2 * var(--hns-global--content-grid--gutter-width) - 11rem);
        top: 15rem;
      }

      .hns-c-cta.index-1 > svg:nth-of-type(3) {
        --dg: -225deg;
        --width-rock: 12.5rem; /* 201px */
        right: calc(-4 * var(--hns-global--content-grid--column-width) - 3 * var(--hns-global--content-grid--gutter-width) - 5.5rem);
        top: 25.5rem;
      }

      .hns-c-cta.index-2 > svg:nth-of-type(1) {
        --dg: -172deg;
        --width-rock: 12.5rem; /* 201px */
        left: calc(-2 * var(--hns-global--content-grid--column-width) - 1 * var(--hns-global--content-grid--gutter-width) - 14.5rem);
        top: 23.25rem;
      }

      .hns-c-cta.index-2 > svg:nth-of-type(2) {
        --dg: -15deg;
        --width-rock: 7rem; /* 111px */
        left: calc(-1 * var(--hns-global--content-grid--column-width) - 1 * var(--hns-global--content-grid--gutter-width) - 10.5rem);
        top: 37.5rem;
      }

      .hns-c-cta.index-2 > svg:nth-of-type(3) {
        --dg: -157deg;
        --width-rock: 7.5rem; /* 122px */
        right: calc(-3 * var(--hns-global--content-grid--column-width) - 3 * var(--hns-global--content-grid--gutter-width) - 2rem);
        top: 23.5rem;
      }

      .hns-c-cta.align-center {
        --column-button-start: -13;
        --column-content-end: -9;
        --column-content-start: 5;
      }

      .hns-c-cta.align-left,
      .hns-c-cta.align-right {
        --column-content-end: span 8;
        --column-count: 12;
        --columns-default:
          [content-start]
          repeat(
            var(--column-count),
            var(--width-column) var(--width-gutter)
          )
          [content-end];
        --gradient-background: linear-gradient(
          var(--angle-cut),
          transparent 0,
          transparent var(--position-cut),
          white var(--position-cut),
          white calc(var(--position-cut) + var(--size-cut)),
          var(--color-mask) calc(var(--position-cut) + var(--size-cut)),
          var(--color-mask) 100%
        );
        --gradient-mask: linear-gradient(
          var(--angle-cut),
          black 0,
          black var(--position-cut),
          transparent var(--position-cut),
          transparent calc(var(--position-cut) + var(--size-cut)),
          black calc(var(--position-cut) + var(--size-cut)),
          black 100%
        );
        --size-cut: .25rem;

        justify-content: end;
      }

      .hns-c-cta.align-left::before,
      .hns-c-cta.align-right::before {
        background-color: initial;
        background-image:
          var(--gradient-background),
          var(--image-background);
        background-position: top left, center;
        justify-content: end;
        mask-image: var(--gradient-mask);
      }

      .hns-c-cta.align-left {
        --angle-cut: 260deg;
        --column-content-start: 6;
        --position-cut: calc(9 * var(--hns-global--content-grid--column-width));
      }

      .hns-c-cta.align-right {
        --angle-cut: 100deg;
        --column-content-start: 14;
        --position-cut: calc(100% - 11 * var(--hns-global--content-grid--column-width));
      }

      .hns-c-cta.align-left > .hns-c-button,
      .hns-c-cta.align-right > .hns-c-button {
        justify-self: start;
      }
    }

  /* interactions */

    .hns-c-cta {
      transition: opacity var(--hns-global--Duration--md) ease-out;
    }

    .hns-c-cta.hns-m-js {
      opacity: 0;
    }

    .hns-c-cta.hns-m-js.hns-m-visible {
      opacity: 1;
    }

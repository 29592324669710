/** ***************************************************************************
 * Organisms / Crags / Sectors
 *************************************************************************** */

/**
 * Sector
 */

.crag-sectors > .hns-c-sectioning:nth-child(2n+1) {
  --hns-c-sectioning--AccentColor-r: var(--hns-global--palette-yellow--60-r);
  --hns-c-sectioning--AccentColor-g: var(--hns-global--palette-yellow--60-g);
  --hns-c-sectioning--AccentColor-b: var(--hns-global--palette-yellow--60-b);
  --hns-c-sectioning--AccentColor-a: 1;

  --hns-c-sectioning--BackgroundColor-r: var(--hns-global--palette-blue--80-r);
  --hns-c-sectioning--BackgroundColor-g: var(--hns-global--palette-blue--80-g);
  --hns-c-sectioning--BackgroundColor-b: var(--hns-global--palette-blue--80-b);
  --hns-c-sectioning--BackgroundColor-a: 1;

  --hns-c-sectioning--Color-r: var(--hns-global--palette-white--100-r);
  --hns-c-sectioning--Color-g: var(--hns-global--palette-white--100-g);
  --hns-c-sectioning--Color-b: var(--hns-global--palette-white--100-b);
  --hns-c-sectioning--Color-a: 1;
}

.crag-sectors > .hns-c-sectioning:nth-child(2n) {
  --hns-c-sectioning--AccentColor-r: var(--hns-global--palette-dark--100-r);
  --hns-c-sectioning--AccentColor-g: var(--hns-global--palette-dark--100-g);
  --hns-c-sectioning--AccentColor-b: var(--hns-global--palette-dark--100-b);
  --hns-c-sectioning--AccentColor-a: 1;

  --hns-c-sectioning--BackgroundColor-r: var(--hns-global--palette-yellow--60-r);
  --hns-c-sectioning--BackgroundColor-g: var(--hns-global--palette-yellow--60-g);
  --hns-c-sectioning--BackgroundColor-b: var(--hns-global--palette-yellow--60-b);
  --hns-c-sectioning--BackgroundColor-a: 1;

  --hns-c-sectioning--Color-r: var(--hns-global--palette-blue--100-r);
  --hns-c-sectioning--Color-g: var(--hns-global--palette-blue--100-g);
  --hns-c-sectioning--Color-b: var(--hns-global--palette-blue--100-b);
  --hns-c-sectioning--Color-a: 1;
}

/** ***************************************************************************
 * Molecules / Sectioning
 *************************************************************************** */

:root {
  --hns-c-sectioning--BackgroundColor-r: var(--hns-c-page--BackgroundColor-r);
  --hns-c-sectioning--BackgroundColor-g: var(--hns-c-page--BackgroundColor-g);
  --hns-c-sectioning--BackgroundColor-b: var(--hns-c-page--BackgroundColor-b);
  --hns-c-sectioning--BackgroundColor-a: var(--hns-c-page--BackgroundColor-a);

  --hns-c-sectioning--Color-r: var(--hns-c-page--Color-r);
  --hns-c-sectioning--Color-g: var(--hns-c-page--Color-g);
  --hns-c-sectioning--Color-b: var(--hns-c-page--Color-b);
  --hns-c-sectioning--Color-a: var(--hns-c-page--Color-a);

  --hns-c-sectioning--AccentColor-r: var(--hns-c-page--Color-r);
  --hns-c-sectioning--AccentColor-g: var(--hns-c-page--Color-g);
  --hns-c-sectioning--AccentColor-b: var(--hns-c-page--Color-b);
  --hns-c-sectioning--AccentColor-a: var(--hns-c-page--Color-a);

  --hns-c-sectioning--tn--PaddingBlockStart: 0;
  --hns-c-sectioning--lg--PaddingBlockStart: 4.54rem;  /* make room for rocks and add 100px */
  --hns-c-sectioning--PaddingBlockStart: var(--hns-c-sectioning--tn--PaddingBlockStart);


  /**
   * Header
   */

  --hns-c-sectioning__header--FontSize: var(--hns-global--FontSize-label--lg);

  --hns-c-sectioning__header--tn--MarginBlockEnd: 2.5rem;  /* 40px */
  --hns-c-sectioning__header--md--MarginBlockEnd: 4.5rem;  /* 72px */
  --hns-c-sectioning__header--MarginBlockEnd: var(--hns-c-sectioning__header--tn--MarginBlockEnd);


  /**
   * Heading
   */

  --hns-c-sectioning__heading--FontFamily: var(--hns-global--FontFamily-special);

  --hns-c-sectioning__heading--tn--FontSize: var(--hns-global--FontSize-title--2xl);
  --hns-c-sectioning__heading--md--FontSize: var(--hns-global--FontSize-title--4xl);
  --hns-c-sectioning__heading--FontSize: var(--hns-c-sectioning__heading--tn--FontSize);

  --hns-c-sectioning--section__heading--FontWeight: var(--hns-global--FontWeight-heavy);
  --hns-c-sectioning--article__heading--FontWeight: var(--hns-global--FontWeight-normal);


  /**
   * Subtitle
   */

  --hns-c-sectioning__subtitle--FontSize: var(--hns-global--FontSize-label--lg);
  --hns-c-sectioning__subtitle--FontWeight: var(--hns-global--FontWeight-bold);

  --hns-c-sectioning__subtitle--tn--MarginBlockEnd: .75rem;  /* 12px */
  --hns-c-sectioning__subtitle--md--MarginBlockEnd: 2rem;  /* 32px */
  --hns-c-sectioning__subtitle--MarginBlockEnd: var(--hns-c-sectioning__subtitle--tn--MarginBlockEnd);
}

/**
 * Medium
 */

@media all and (min-width: 64rem) {
  :root {
    --hns-c-sectioning__header--MarginBlockEnd: var(--hns-c-sectioning__header--md--MarginBlockEnd);
    --hns-c-sectioning__heading--FontSize: var(--hns-c-sectioning__heading--md--FontSize);
    --hns-c-sectioning__subtitle--MarginBlockEnd: var(--hns-c-sectioning__subtitle--md--MarginBlockEnd);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  :root {
    --hns-c-sectioning--PaddingBlockStart: var(--hns-c-sectioning--lg--PaddingBlockStart);
  }
}



/**
 * Component
 * 1: rocks
 */

.hns-c-sectioning {
  accent-color: rgba(
    var(--hns-c-sectioning--AccentColor-r),
    var(--hns-c-sectioning--AccentColor-g),
    var(--hns-c-sectioning--AccentColor-b),
    var(--hns-c-sectioning--AccentColor-a)
  );
  background-color: rgba(
    var(--hns-c-sectioning--BackgroundColor-r),
    var(--hns-c-sectioning--BackgroundColor-g),
    var(--hns-c-sectioning--BackgroundColor-b),
    var(--hns-c-sectioning--BackgroundColor-a)
  );
  color: rgba(
    var(--hns-c-sectioning--Color-r),
    var(--hns-c-sectioning--Color-g),
    var(--hns-c-sectioning--Color-b),
    var(--hns-c-sectioning--Color-a)
  );
  display: grid;
  grid-template-columns: var(--columns-default);
  overflow-x: hidden;  /* 1 */
  padding-block: var(--hns-c-sectioning--PaddingBlockStart) 0;
  padding-inline: 0;
}

@supports not (padding-block: var(--hns-c-sectioning--PaddingBlockStart) 0) {
  .hns-c-sectioning {
    padding: var(--hns-c-sectioning--PaddingBlockStart) 0 0 0;
  }
}


/**
 * Header
 */

.hns-c-sectioning > header {
  display: grid;
  font-size: var(--hns-c-sectioning__header--FontSize);
  grid:
    "subtitle"
    "heading";
  justify-items: start;
  margin-block-end: var(--hns-c-sectioning__header--MarginBlockEnd);
  position: relative;
  z-index: 1;
}

@supports not (margin-block-end: var(--hns-c-sectioning__header--MarginBlockEnd)) {
  .hns-c-sectioning > header {
    margin-bottom: var(--hns-c-sectioning__header--MarginBlockEnd);
  }
}



/**
 * Heading
 */

.hns-c-sectioning > header > :first-child {
  font-family: var(--hns-c-sectioning__heading--FontFamily);
  font-size: var(--hns-c-sectioning__heading--FontSize);
  grid-area: heading;
}

article.hns-c-sectioning > header > :first-child {
  font-weight: var(--hns-c-sectioning--article__heading--FontWeight);
}

section.hns-c-sectioning > header > :first-child {
  font-weight: var(--hns-c-sectioning--section__heading--FontWeight);
}


/**
 * Subtitle
 */

.hns-c-sectioning > header > .hns-c-sectioning__subtitle {
  color: rgba(
    var(--hns-c-sectioning--AccentColor-r),
    var(--hns-c-sectioning--AccentColor-g),
    var(--hns-c-sectioning--AccentColor-b),
    var(--hns-c-sectioning--AccentColor-a)
  );
  font-size: var(--hns-c-sectioning__subtitle--FontSize);
  font-weight: var(--hns-c-sectioning__subtitle--FontWeight);
  grid-area: subtitle;
  margin-block-end: var(--hns-c-sectioning__subtitle--MarginBlockEnd);
}

@supports not (margin-block-end: var(--hns-c-sectioning__subtitle--MarginBlockEnd)) {
  .hns-c-sectioning > header > .hns-c-sectioning__subtitle {
    margin-bottom: var(--hns-c-sectioning__subtitle--MarginBlockEnd);
  }
}



/**
 * Old code
 */

.hns-c-sectioning > header ~ * {
  font-size: var(--hns-global--FontSize-paragraph--md);
}


.hns-c-sectioning > * {
  grid-column: var(--column-content-start) / var(--column-content-end);
}

.hns-c-sectioning > header > * {
  margin: 0;
}

.hns-c-sectioning > header > svg {
  display: none;
}

.hns-c-sectioning > p {
  margin: .5em 0;
}

.hns-c-sectioning > header + p {
  margin-top: 0;
}

.hns-c-sectioning > p:first-child {
  margin-top: 1em;
}

.hns-c-sectioning > p:last-child {
  margin-bottom: 1em;
}

@media (min-width: 90rem) { /* hg */
  .hns-c-sectioning > header > svg {
    display: block;
    position: absolute;
    top: 0;
    transform-origin: top left;
    z-index: -1;
  }

  .hns-c-sectioning > header > svg:nth-of-type(1) {
    transform: translate(-200%, -30%) rotate(-20deg);
    width: 5.1rem; /* half (can't use scale on Safari) */
  }

  .hns-c-sectioning > header > svg:nth-of-type(2) {
    transform: translate(50%, 20%) rotate(131deg);
    width: 10.18rem; /* 163px */
  }
}

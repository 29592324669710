/* charlie holder */

  /* colors */

    .charlie-holder {
      --color-foreground: rgba(
        var(--color-foreground-r),
        var(--color-foreground-g),
        var(--color-foreground-b),
        var(--color-foreground-a)
      );
      --color-special: rgba(
        var(--color-special-r),
        var(--color-special-g),
        var(--color-special-b),
        var(--color-special-a)
      );
    }

  /* layout */

    @supports (display: contents) {
      /* BEGIN: main grid */

        /*
         * md
         *
         * start making rows
         * two columns
         */
        @media (min-width: 48rem) {
          .charlie-holder {
            --charlie-column-count: 2;
            --width-column: calc(
              2rem +     /* row-gap */
              6rem +     /* icon    */
              2rem +     /* heading */
              2 * 5.2rem /* padding */
            );
            display: grid;
            grid-auto-flow: dense;
            grid-template-columns:
              [row-start]
              1fr
              repeat(
                var(--charlie-column-count),
                var(--width-column)
              )
              1fr
              [row-start];
          }

          .charlie-holder > section {
            display: contents;
          }

          .charlie-holder > section:only-of-type > header,
          .charlie-holder > section > div {
            grid-column: row-start / row-end;
          }
        }

        /*
         * lg
         *
         * three columns
         */
        @media (min-width: 64rem) {
          .charlie-holder {
            --charlie-column-count: 3;
            grid-template-columns:
              [row-start]
              1fr
              var(--width-column)
              repeat(
                calc(2 * (var(--charlie-column-count) - 2)),
                calc(var(--width-column) / 2)
              )
              var(--width-column)
              1fr
              [row-start];
          }
        }

        /*
         * hg
         *
         * four columns
         */
        @media (min-width: 90rem) {
          .charlie-holder {
            --charlie-column-count: 4;
          }
        }

      /* END: main grid */

      /* BEGIN: cell positions */

        @media (min-width: 48rem) { /* md */
          .charlie-holder > section > header {
            grid-column-end: span 2;
          }
        }

        @media (min-width: 48rem) and (max-width: 1023px) { /* md -> lg */
          /* 2 columns (no change)

          .charlie-holder > section > header {
            grid-column-end: span 2;
          }
          */

          /* 1 column */

          .charlie-holder > section:nth-last-of-type(odd):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(odd):first-of-type ~ section > header {
            grid-column-end: span 4;
          }
        }

        @media (min-width: 64rem) and (max-width: 1439px) { /* lg -> xl */
          /* 3 columns (no change)

          .charlie-holder > section > header {
            grid-column-end: span 2;
          }
          */

          /* 2 columns */

          .charlie-holder > section:nth-last-of-type(4):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(3n+4):first-of-type ~ section:nth-last-of-type(-n+4) > header,
          .charlie-holder > section:nth-last-of-type(2):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(3n+2):first-of-type ~ section:nth-last-of-type(-n+2) > header {
            grid-column-end: span 3;
          }
        }

        @media (min-width: 90rem) { /* hg */
          /* 4 columns (no change)

          .charlie-holder > section > header {
            grid-column-end: span 2;
          }
          */

          /* 3 columns */

          /* outer */
          .charlie-holder > section:nth-last-of-type(3):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(2n+1):nth-last-of-type(-n+3) > header,
          .charlie-holder > section:nth-last-of-type(5):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(2n+3):nth-last-of-type(-n+5) > header,
          .charlie-holder > section:nth-last-of-type(6):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(3n+1):nth-last-of-type(-n+6) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(3n):nth-last-of-type(-n+6) > header {
            grid-column-end: span 3;
          }

          /* inner (doesn't change)
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(2) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(4) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(3n+2):nth-last-of-type(-n+6) > header {
            grid-column-end: span 2;
          }
          */

          /* 2 columns */

          .charlie-holder > section:nth-last-of-type(2):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(2):first-of-type ~ section > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(-n+2) > header {
            grid-column-end: span 4;
          }
        }

      /* END: cell positions */

      /* BEGIN: shapes */

        @media (min-width: 48rem) { /* md */
          .charlie-holder {
            --slope-vertical: var(--hns-global--content-grid--gutter-width);
            --shape-overhang: polygon(
              0 0,
              100% 0,
              100% 100%,
              var(--slope-vertical) 100%
            );
            --shape-slab: polygon(
              var(--slope-vertical) 0,
              100% 0,
              100% 100%,
              0 100%
            );
          }
        }

        @media (min-width: 48rem) and (max-width: 1023px) { /* md -> lg */
          /* even children, second column, odd rows */
          .charlie-holder > section:nth-last-of-type(even):first-of-type ~ section:nth-of-type(4n+2) > header {
            clip-path: var(--shape-slab);
          }

          /* even children, second column, even rows */
          .charlie-holder > section:nth-last-of-type(even):first-of-type ~ section:nth-of-type(4n) > header {
            clip-path: var(--shape-overhang);
          }

        }

        @media (min-width: 64rem) and (max-width: 1439px) { /* lg -> xl */
          .charlie-holder > section:nth-of-type(2n) > header,
          .charlie-holder > section:nth-last-of-type(3n+7):first-of-type ~ section:nth-last-of-type(3) > header {
            clip-path: var(--shape-slab);
          }

          .charlie-holder > section:nth-of-type(2n+3) > header,
          .charlie-holder > section:nth-last-of-type(4):first-of-type ~ section:nth-of-type(4) > header,
          .charlie-holder > section:nth-last-of-type(3n+8):first-of-type ~ section:nth-last-of-type(1) > header,
          .charlie-holder > section:nth-last-of-type(3n+7):first-of-type ~ section:nth-last-of-type(1) > header {
            clip-path: var(--shape-overhang);
          }

          .charlie-holder > section:nth-of-type(3n+1) > header,
          .charlie-holder > section:nth-last-of-type(4):first-of-type ~ section:nth-of-type(3) > header,
          .charlie-holder > section:nth-last-of-type(3n+7):first-of-type ~ section:nth-last-of-type(2) > header,
          .charlie-holder > section:nth-last-of-type(3n+7):first-of-type ~ section:nth-last-of-type(4) > header {
            clip-path: initial;
          }
        }

        @media (min-width: 90rem) { /* hg */
          .charlie-holder > section:nth-of-type(8n+2) > header,
          .charlie-holder > section:nth-of-type(8n+8) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(2) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(3n+1):nth-last-of-type(-n+5) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(4n+1):nth-last-of-type(-n+6) > header {
            clip-path: var(--shape-slab);
          }

          .charlie-holder > section:nth-of-type(8n+4) > header,
          .charlie-holder > section:nth-of-type(8n+6) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(1) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(3) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(2n):nth-last-of-type(-n+4) > header {
            clip-path: var(--shape-overhang);
          }
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(2) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(3) > header {
            clip-path: initial;
          }
        }
      /* END: shapes */

      /* BEGIN: margins and icon alignment */

        @media (min-width: 48rem) { /* md */
          .charlie-holder > section > header {
            margin-left: calc(-1 * var(--slope-vertical) / 2);
            margin-right: calc(-1 * var(--slope-vertical) / 2);
          }
        }

        @media (min-width: 48rem) and (max-width: 1023px) { /* md -> lg */
          /* even children, first column */
          .charlie-holder > section:nth-last-of-type(even):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(even):first-of-type ~ section:nth-of-type(2n+1) > header {
            --margin-right: 0;
            margin-left: 0;
          }

          /* even children, second column */
          .charlie-holder > section:nth-last-of-type(even):first-of-type ~ section:nth-of-type(2n) > header {
            --margin-left: 0;
            margin-right: 0;
          }
        }

        @media (min-width: 64rem) and (max-width: 1439px) { /* lg -> xl */
          /* first column */
          .charlie-holder > section:not(:only-of-type):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(3n):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(3n):first-of-type ~ section:nth-of-type(3n+1) > header,
          .charlie-holder > section:nth-last-of-type(3n+2):first-of-type ~ section:nth-last-of-type(2) > header,
          .charlie-holder > section:nth-last-of-type(3n+2):first-of-type ~ section:nth-last-of-type(3n+5) > header,
          .charlie-holder > section:nth-last-of-type(3n+4):first-of-type ~ section:nth-last-of-type(2n+2):nth-last-of-type(-n+4) > header,
          .charlie-holder > section:nth-last-of-type(3n+4):first-of-type ~ section:nth-last-of-type(3n+7) > header {
            --margin-right: 0;
            margin-left: 0;
          }

          /* last column */
          .charlie-holder > section:nth-last-of-type(3n):first-of-type ~ section:nth-of-type(3n+3) > header,
          .charlie-holder > section:nth-last-of-type(3n+2):first-of-type ~ section:last-of-type > header,
          .charlie-holder > section:nth-last-of-type(3n+2):first-of-type ~ section:nth-last-of-type(3n+3) > header,
          .charlie-holder > section:nth-last-of-type(3n+4):first-of-type ~ section:nth-last-of-type(2n+1):nth-last-of-type(-n+4) > header,
          .charlie-holder > section:nth-last-of-type(3n+4):first-of-type ~ section:nth-last-of-type(3n+5) > header {
            --margin-left: 0;
            margin-right: 0;
          }
        }

        @media (min-width: 90rem) { /* hg */
          /* second column of four */
          .charlie-holder > section:nth-last-of-type(4n):first-of-type ~ section:nth-of-type(4n+2) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-of-type(4n+2):nth-last-of-type(n+3) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-of-type(4n+2):nth-last-of-type(n+5) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-of-type(4n+2):nth-last-of-type(n+6) > header {
            margin-right: 0;
          }

          /* first column */
          .charlie-holder > section:not(:only-of-type):first-of-type > header,
          .charlie-holder > section:nth-last-of-type(4n):first-of-type ~ section:nth-of-type(4n+1) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(3) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(4n+7) > header,

          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(3n+2):nth-last-of-type(-n+5) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(4n+9) > header,

          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(3n+3):nth-last-of-type(-n+6) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(4n+10) > header {
            --margin-right: 0;
            margin-left: 0;
          }

          /* last column */
          .charlie-holder > section:not(:only-of-type):last-of-type > header,
          .charlie-holder > section:nth-last-of-type(4n):first-of-type ~ section:nth-of-type(4n+4) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(1) > header,
          .charlie-holder > section:nth-last-of-type(4n+3):first-of-type ~ section:nth-last-of-type(4n+4) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(2n+1):nth-last-of-type(-n+3) > header,
          .charlie-holder > section:nth-last-of-type(4n+5):first-of-type ~ section:nth-last-of-type(4n+6) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(3n+1):nth-last-of-type(-n+6) > header,
          .charlie-holder > section:nth-last-of-type(4n+6):first-of-type ~ section:nth-last-of-type(4n+7) > header {
            --margin-left: 0;
            margin-right: 0;
          }
        }

      /* END: margins and icon alignment */
    }

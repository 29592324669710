/** ***************************************************************************
 * Organisms / Crags / Filters
 *************************************************************************** */

:root {
  --hns-c-crag-filters--BackgroundColor: var(--hns-global--palette-yellow--50);
  --hns-c-crag-filters--Color: var(--hns-global--palette-blue);


  /**
   * Buttons
   */

  --hns-c-crag-filters__buttons--FontSize: var(--hns-global--FontSize-paragraph--md);

  --hns-c-crag-filters__buttons--tn--AlignItems: stretch;
  --hns-c-crag-filters__buttons--md--AlignItems: center;
  --hns-c-crag-filters__buttons--AlignItems: var(--hns-c-crag-filters__buttons--tn--AlignItems);

  --hns-c-crag-filters__buttons--tn--FlexDirection: column;
  --hns-c-crag-filters__buttons--md--FlexDirection: row;
  --hns-c-crag-filters__buttons--FlexDirection: var(--hns-c-crag-filters__buttons--tn--FlexDirection);


  /**
   * Button
   */

  --hns-c-crag-filters__button--MarginBlockStart: 3.25rem;  /* 52px */
  --hns-c-crag-filters__button--MarginInline: .75rem;  /* 12px */


  /**
   * Reset button
   */

  --hns-c-crag-filters__reset--FontSize: smaller;
}

/**
 * Small
 */

@media all and (min-width: 34rem) {
  :root {

  }
}

/**
 * Medium
 */

@media all and (min-width: 48rem) {
  :root {
    --hns-c-crag-filters__buttons--AlignItems: var(--hns-c-crag-filters__buttons--md--AlignItems);
    --hns-c-crag-filters__buttons--FlexDirection: var(--hns-c-crag-filters__buttons--md--FlexDirection);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  :root {

  }
}



/**
 * Component
 */

#hns-c-crag-filters {
  background-color: var(--hns-c-crag-filters--BackgroundColor);
  color: var(--hns-c-crag-filters--Color);
}



/**
 * Header
 * 1: contain label
 */

#hns-c-crag-filters > header {
  position: relative;  /* 1 */
}


/**
 * Buttons
 */

.hns-c-crag-filters__buttons {
  align-items: var(--hns-c-crag-filters__buttons--AlignItems);
  display: flex;
  flex-direction: var(--hns-c-crag-filters__buttons--FlexDirection);
  font-size: var(--hns-c-crag-filters__buttons--FontSize);
  justify-content: center;
}



/**
 * Button
 */

.hns-c-crag-filters__buttons .hns-c-button {
  margin-block: var(--hns-c-crag-filters__button--MarginBlockStart) 0;
  margin-inline: var(--hns-c-crag-filters__button--MarginInline);
  vertical-align: middle;
}

@supports not (margin-inline: var(--hns-c-crag-filters__button--MarginInline)) {
  .hns-c-crag-filters__buttons .hns-c-button {
    margin:
      var(--hns-c-crag-filters__button--MarginBlockStart)
      var(--hns-c-crag-filters__button--MarginInline)
      0
      var(--hns-c-crag-filters__button--MarginInline);
  }
}


/**
 * Reset button
 */

.hns-c-crag-filters__buttons a.hns-u-button {
  font-size: var(--hns-c-crag-filters__reset--FontSize);
}



/* Old code */

  /* typography */

    #hns-c-crag-filters h2 {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--2xl); /* FIXME: must become xl*/
      font-weight: var(--hns-global--FontWeight-heavy);
    }

    #hns-c-crag-filters .subtitle {
      font-family: var(--hns-global--FontFamily-normal);
      font-size: var(--hns-global--FontSize-subtitle--xs);
      font-weight: var(--hns-global--FontWeight-regular);
    }

    #hns-c-crag-filters label {
      font-family: var(--hns-global--FontFamily-normal);
      font-size: var(--hns-global--FontSize-subtitle--xs);
      font-weight: var(--hns-global--FontWeight-regular);
    }

    #hns-c-crag-filters > header label::after {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--xs);
      font-weight: var(--hns-global--FontWeight-heavy);
    }

    #hns-c-crag-filters .legend {
      font-family: var(--hns-global--FontFamily-special);
      font-size: var(--hns-global--FontSize-title--xs);
      font-weight: var(--hns-global--FontWeight-heavy);
    }

  /* colors */

    #hns-c-crag-filters {
      --color-highlight: rgba(
        var(--hns-global--palette-blue--100-r),
        var(--hns-global--palette-blue--100-g),
        var(--hns-global--palette-blue--100-b),
        .5
      );
    }

  /* layout */

    #hns-c-crag-filters {
      --columns-content: 5 / -5;
      --gap-row: 4.5rem; /* 72px */
      --margin-after: 2rem;
      --padding-after: 2.5rem; /* 42px */
      --padding-before: 4.75rem; /* 75px */
      --width-cell: 6.6rem;

      display: grid;
      grid-row-gap: var(--gap-row);
      grid-template-columns: var(--columns-default);
      padding-top: var(--padding-before);
      margin-bottom: var(--margin-after);
    }

    #hns-c-crag-filters > * {
      grid-column: var(--columns-content);
    }

    #hns-c-crag-filters > header {
      --size-icons: 4.25rem; /* 68px */
    }

    #hns-c-crag-filters > header > * {
      margin: 0;
    }

    #hns-c-crag-filters > header .subtitle {
      margin: 1.25rem 0; /* 20px */
    }

    #hns-c-crag-filters > form {
      --size-icons: 2.25rem; /* 36px */

      box-sizing: border-box;
      text-align: center;
    }

    #hns-c-crag-filters > form::after {
      display: block;
      content: "";
      height: var(--padding-after);
    }

    #hns-c-crag-filters svg {
      height: var(--size-icons);
      width: var(--size-icons);
    }

    #hns-c-crag-filters > header label::after {
      margin-left: 1rem;
    }

    #hns-c-crag-filters form input {
      display: block;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      visibility: hidden;
    }

    #hns-c-crag-filters fieldset {
      --margin-before: 2.5rem; /* 42px */
      --padding-before: 1.25rem; /* 19px */

      border: 0;
      margin: 0;
      padding: 0;
    }

    #hns-c-crag-filters fieldset:nth-of-type(n+2) {
      margin-top: var(--margin-before);
    }

    #hns-c-crag-filters fieldset > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(var(--width-cell), 1fr));
      justify-items: center;
    }

    #hns-c-crag-filters .legend {
      grid-column: 1 / -1;
      margin-bottom: var(--padding-before);
      text-align: center;
      width: 100%;
    }

    #hns-c-crag-filters form label {
      align-items: center;
      border: 1px solid var(--color-border, transparent);
      display: inline-flex;
      flex-direction: column;
      height: 5.5rem; /* 88px */
      justify-content: space-around;
      text-align: center;
      width: var(--width-cell); /* 106px */
    }


    @media (min-width: 34rem) { /* sm */
      #hns-c-crag-filters {
        --columns-content: 4 / -4;
      }

      #hns-c-crag-filters fieldset > div {
        grid-template-columns: repeat(4, 1fr);
      }

      #hns-c-crag-filters .legend {
        grid-column: 1 / -1;
      }
    }

    @media (min-width: 48rem) { /* md */
      #hns-c-crag-filters {
        --columns-content: 4 / -4;
        --margin-after: 4rem;
      }

      #hns-c-crag-filters fieldset:nth-last-of-type(n+2) {
        border-top: 1px solid currentColor;
        padding-top: var(--padding-before);
      }

        #hns-c-crag-filters fieldset:nth-last-of-type(2) {
          border-bottom: 1px solid currentColor;
          padding-bottom: var(--margin-before);
        }

        #hns-c-crag-filters fieldset > div {
          grid-template-columns:
            calc(4 * var(--hns-global--content-grid--column-width) + 4 * var(--hns-global--content-grid--gutter-width))
            repeat(4, 1fr);
        }


      #hns-c-crag-filters .legend {
        grid-column: 1 / span 1;
        justify-self: stretch;
        margin-right: var(--hns-global--content-grid--gutter-width);
        text-align: left;
      }
    }

    @media (min-width: 64rem) { /* lg */
      #hns-c-crag-filters {
        --columns-content: 5 / -5;
      }

      #hns-c-crag-filters > header {
        display: grid;
        grid-column-gap: var(--hns-global--content-grid--gutter-width);
        grid-row-gap: 1.5rem; /* 24px */
        grid:
          "title    button"
          "subtitle button"
        / 7fr       3fr;
      }

      #hns-c-crag-filters > header h2 {
        grid-area: title;
      }

      #hns-c-crag-filters > header .subtitle {
        grid-area: subtitle;
        margin: 0;
      }

      #hns-c-crag-filters > header label {
        grid-area: button;
      }
    }

  /* interactions */

    #hns-c-crag-filters > header label::after {
      content: attr(data-label-open);
      display: inline;
      transition: content 1s linear;
      vertical-align: middle;
    }

    #hns-c-crag-filters > .hns-c-toggle:checked ~ header label::after {
      content: attr(data-label-close);
    }

    #hns-c-crag-filters > header svg {
      transform: rotateZ(90deg);
      transition: transform var(--hns-global--Duration--sm) linear;
      vertical-align: middle;
    }

    #hns-c-crag-filters > .hns-c-toggle:checked ~ header svg {
      transform: rotateZ(-90deg);
    }

    #hns-c-crag-filters > form {
      max-height: 0;
      overflow: hidden;
      transition: max-height var(--hns-global--Duration--sm) linear;
    }

    #hns-c-crag-filters > .hns-c-toggle:checked ~ form {
      max-height: var(--height-content, none);
    }

    #hns-c-crag-filters form label {
      transition: border-color var(--hns-global--Duration--sm) linear;
    }

    #hns-c-crag-filters input:checked + label {
      --color-border: currentColor;
    }

    @media (hover: hover) {

      #hns-c-crag-filters > header label > svg {
        transition: transform var(--hns-global--Duration--sm) linear;
      }

        #hns-c-crag-filters > header label:hover > svg,
        #hns-c-crag-filters > header label:active > svg {
          transform: scale(1.1);
        }

      #hns-c-crag-filters form label:hover,
      #hns-c-crag-filters form label:active {
        --color-border: var(--color-highlight);
      }
    }

  /* stuff that should be elsewhere */

    #search-results > p:first-child {
      font-size: var(--hns-global--FontSize-paragraph--md);
      padding-top: 2.5em;
    }

    #search-results {
      margin-bottom: 1rem;
    }

    @media (min-width: 64rem) { /* lg */
      #search-results {
        margin-bottom: 6rem;
      }
    }

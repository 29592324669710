/** ***************************************************************************
 * Organisms / CMS plugins / Image gallery
 *************************************************************************** */

.hns-c-gallery {
  --hns-c-gallery--Gap: .25rem;  /* 4px */
  --hns-c-gallery--PaddingBlock: var(--hns-c-gallery--Gap);


  /**
   * Thumbnail
   */

  --hns-c-gallery__thumbnail--Height: 18.75rem;  /* 300px */
}



/**
 * Component
 * 1: simulate gap
 */

.hns-c-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--hns-c-gallery--Gap);
  padding-block: var(--hns-c-gallery--PaddingBlock);
}

@supports not (padding-block: var(--hns-c-gallery--PaddingBlock)) {
  .hns-c-gallery {
    padding-top: var(--hns-c-gallery--PaddingBlock);
    padding-bottom: var(--hns-c-gallery--PaddingBlock);
  }
}



/**
 * Link
 */

.hns-c-gallery > a {
  display: contents;
}



/**
 * Thumbnail
 * 1: fill rows
 */

.hns-c-gallery > a > img {
  display: block;
  flex-grow: 1;  /* 1 */
  height: var(--hns-c-gallery__thumbnail--Height);
  object-fit: cover;
  object-position: center;
  width: auto;
}

/** ***********************************************************************************
 * Strong text styles
 *********************************************************************************** */

:root {
  --hns-c-page__strong--FontWeight: var(--hns-global--FontWeight-bold);
}



b,
strong {
  font-weight: var(--hns-c-page__strong--FontWeight);
}

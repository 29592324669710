/** ***************************************************************************
 * Molecules / Crags / Route table
 **************************************************************************** */

:root {
  --hns-c-route-table--FontSize: var(--hns-global--FontSize-subtitle--sm);
  --hns-c-route-table--FontFamily: var(--hns-global--FontFamily-special);
  --hns-c-route-table--FontWeight: var(--hns-global--FontWeight-medium);


  /**
   * Route index
   */

  --hns-c-route-table__index--FontSize: var(--hns-global--FontSize-list--md);
  --hns-c-route-table__index--FontWeight: var(--hns-global--FontWeight-heavy);

  --hns-c-route-table__index--tn--PaddingInlineEnd: initial;
  --hns-c-route-table__index--md--PaddingInlineEnd: 1rem;
  --hns-c-route-table__index--PaddingInlineEnd: var(--hns-c-route-table__index--tn--PaddingInlineEnd);

  --hns-c-route-table__index--tn--TextAlign: inherit;
  --hns-c-route-table__index--md--TextAlign: right;
  --hns-c-route-table__index--TextAlign: var(--hns-c-route-table__index--tn--TextAlign);

  --hns-c-route-table__index--tn--Width: auto;
  --hns-c-route-table__index--md--Width: 3rem;
  --hns-c-route-table__index--Width: var(--hns-c-route-table__index--tn--Width);


  /**
   * Route and pitch name
   */

  --hns-c-route-table__name--FontSize: var(--hns-global--FontSize-title--xs);
  --hns-c-route-table__name--FontWeight: var(--hns-global--FontWeight-heavy);

  --hns-c-route-table__name--tn--Width: initial;
  --hns-c-route-table__name--md--Width: auto;
  --hns-c-route-table__name--Width: var(--hns-c-route-table__name--tn--Width);


  /**
   * Pitch name
   */

  --hns-c-route-table__pitch-name--FontWeight: var(--hns-global--FontWeight-regular);
}

/**
 * Medium
 */

@media screen and (min-width: 48rem) {
  :root {
    --hns-c-route-table__index--PaddingInlineEnd: var(--hns-c-route-table__index--md--PaddingInlineEnd);
    --hns-c-route-table__index--TextAlign: var(--hns-c-route-table__index--md--TextAlign);
    --hns-c-route-table__index--Width: var(--hns-c-route-table__index--md--Width);
    --hns-c-route-table__name--Width: var(--hns-c-route-table__name--md--Width);
  }
}



/**
 * Component
 */

.hns-c-route-table {
  font-family: var(--hns-c-route-table--FontFamily);
  font-size: var(--hns-c-route-table--FontSize);
  font-weight: var(--hns-c-route-table--FontWeight);
}


/**
 * Route index
 */

.hns-c-route-table col.index {
  width: var(--hns-c-route-table__index--Width);
}

.hns-c-route-table tr > .index {
  font-size: var(--hns-c-route-table__index--FontSize);
  font-weight: var(--hns-c-route-table__index--FontWeight);
  padding-inline-end: var(--hns-c-route-table__index--PaddingInlineEnd);
  text-align: var(--hns-c-route-table__index--TextAlign);
}

.hns-c-route-table tr > .index {
  grid-area: index;
  justify-self: end;
}


/**
 * Route and pitch name
 */

.hns-c-route-table col.name {
  width: var(--hns-c-route-table__name--Width);
}

.hns-c-route-table tr > .name {
  font-size: var(--hns-c-route-table__name--FontSize);
  text-align: left;
}

.hns-c-route-table tbody tr > .name {
  font-weight: var(--hns-c-route-table__name--FontWeight);
  grid-area: name;
  justify-self: stretch;
  text-transform: uppercase;
}


/**
 * Pitch name
 */

.hns-c-route-table tbody tr > :first-child:empty + .name {
  font-weight: var(--hns-c-route-table__pitch-name--FontWeight);
  text-transform: none;
}



/**
 * Old code
 */

  /* typography */

    /* pitch name */

      .hns-c-route-table tbody tr > :first-child:empty + .name {
        font-weight: var(--hns-global--FontWeight-regular);
        text-transform: none;
      }

    /* route difficulty */

      .hns-c-route-table col.difficulty {

      }

      .hns-c-route-table tbody tr > .difficulty {

      }

    /* route length */

      .hns-c-route-table col.length {

      }

      .hns-c-route-table tbody tr > .length {

      }

    /* notes */

      .hns-c-route-table tbody tr > .notes {
        font-family: var(--hns-global--FontFamily-normal);
        font-size: 1rem;
        font-weight: var(--hns-global--FontWeight-bold);
        text-align: right;
        vertical-align: middle;
      }

      .hns-c-route-table tr.notes {
        font-family: var(--hns-global--FontFamily-normal);
        font-size: var(--hns-global--FontSize-paragraph--sm);
      }

  /* layout */

    /* info button */
    .hns-c-route-table tr > .notes svg {
      height: .5rem;
      transform: rotateZ(90deg);
      transition: transform var(--hns-global--Duration--sm) linear;
      vertical-align: middle;
      width: .4rem;
    }

      .hns-c-route-table [data-status=open] .notes svg {
        transform: rotateZ(-90deg);
      }


    .hns-c-route-table > caption,
    .hns-c-route-table > thead {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    .hns-c-route-table,
    .hns-c-route-table tbody,
    .hns-c-route-table tr > * {
      display: block;
    }

    .hns-c-route-table tr {
      display: grid;
    }

    .hns-c-route-table tr.notes {
      display: block;
    }

  /* table */

    .hns-c-route-table tr {
      grid:
        "index             .    name       name   name"
        ".                 .    difficulty length notes"
      / minmax(2rem, auto) 1rem 1fr        1fr    1fr;
      align-items: center;
      justify-items: start;
      row-gap: .31rem; /* 5px */
    }

    .hns-c-route-table > .multi-pitch > :first-child > .difficulty,
    .hns-c-route-table > .multi-pitch > :first-child > .length {
      height: 0;
      opacity: 0;
      pointer-events: none;
    }

    .hns-c-route-table > tbody tr:first-child {
      row-gap: .69rem; /* 11px */
    }

    .hns-c-route-table > tbody > tr:nth-child(n+3) {
      margin-top: 0;
    }

    .hns-c-route-table > tbody > tr:nth-child(2):not([class~=notes]),
    .hns-c-route-table tr.notes + tr {
      /*margin-top: 1.5rem;*/ /* 24px */
    }

    .hns-c-route-table tr.notes {
      padding-left: 3rem; /* same as the first column */
    }

    .hns-c-route-table tr > .name {
      grid-area: name;
      justify-self: stretch;
    }

    .hns-c-route-table .route {
      padding-top: 1rem;
    }

    .hns-c-route-table tr:first-child > .name {
      border-bottom: 1px solid currentColor;
    }

    .hns-c-route-table tr > .difficulty {
      grid-area: difficulty;
    }

    .hns-c-route-table tr > .length {
      grid-area: length;
    }

    .hns-c-route-table tr > .notes {
      grid-area: notes;
      justify-self: end;
    }

    .hns-c-route-table tr.notes .hns-c-toggle + div {
      margin: 0;
      max-height: 0;
      overflow: hidden;
      transition: max-height var(--hns-global--Duration--sm) linear;
    }

    .hns-c-route-table tr.notes .hns-c-toggle:checked + div {
      max-height: var(--height-info, none);
    }

    @media screen and (min-width: 48rem) { /* md */
      .hns-c-route-table col {
        width: auto;
      }

      .hns-c-route-table col.difficulty {
        width: 6.5rem;
      }

      .hns-c-route-table col.length {
        width: 10%;
      }

      .hns-c-route-table col.notes {
        width: 4rem;
      }

      .hns-c-route-table tr > * {
        padding: 0;
      }

      .hns-c-sector table {
        border-collapse: collapse;
        display: table;
        table-layout: fixed;
        width: 100%;
      }

      .hns-c-route-table tbody {
        display: table-row-group;
      }

      .hns-c-route-table tr,
      .hns-c-route-table tr.notes {
        display: table-row;
      }

      .hns-c-route-table tr > * {
        display: table-cell;
      }

      .hns-c-route-table tr:first-child > .name {
        border: 0;
      }

      .hns-c-route-table tr:first-child > * {
        padding-top: 3.325rem; /* 50px */
      }

      .hns-c-route-table tbody:first-of-type > tr:first-child > * {
        padding-top: 0;
      }

      .hns-c-route-table tr:nth-child(n+2) > * {
        padding-top: 1.375rem; /* 22px */
      }

      .hns-c-route-table tr.notes > * {
        padding-top: 0;
      }
    }

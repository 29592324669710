/** ***********************************************************************************
 * Molecules / Reserved area / Partner modal
 *********************************************************************************** */

.hns-c-partner-modal {
  --hns-c-partner-modal--BackgroundColor: var(--hns-global--palette-white);
  --hns-c-partner-modal--Color: var(--hns-global--palette-dark);
  --hns-c-partner-modal--PaddingBlock: clamp(1em, 11vh, 7em);  /* 23px / 97px */
  --hns-c-partner-modal--PaddingInline: clamp(2.14em, 10vw, 11em);  /* 30px / 155px */


  /**
   * Close button
   */

  --hns-c-partner-modal__button--BlockSize: 2rem;  /* 32px */
  --hns-c-partner-modal__button--InsetBlockStart: 1.64em;  /* 23px */
  --hns-c-partner-modal__button--InsetInlineEnd: var(--hns-c-partner-modal__button--InsetBlockStart);


  /**
   * Logo
   */

  --hns-c-partner-modal__logo--BlockSize: 7.5rem;  /* 121px */
  --hns-c-partner-modal__logo--MarginBlockEnd: 2em;  /* 29px */


  /**
   * Heading
   */

  --hns-c-partner-modal__heading--FontSize: var(--hns-global--FontSize-title--md);
  --hns-c-partner-modal__heading--MarginBlock: .35em;  /* 9px */


  /**
   * Subheading
   */

  --hns-c-partner-modal__subheading--FontWeight: var(--hns-global--FontWeight-normal);


  /**
   * Backdrop
   */

  --hns-c-partner-modal__backdrop--BackgroundColor: rgba(
    var(--hns-global--palette-blue--100-r),
    var(--hns-global--palette-blue--100-g),
    var(--hns-global--palette-blue--100-b),
    .95
  );


  /**
   * Content
   */

  --hns-c-partner-modal__content--PaddingBlock: 1em;  /* 14px */
  --hns-c-partner-modal__content--MarginBlockEnd: 1.43em;  /* 20px */
  --hns-c-partner-modal__content--MarginBlockStart: .64em;  /* 9px */


  /**
   * Subscriber data
   */

  --hns-c-partner-modal__subscriber-data--MarginInlineStart: calc(1.875rem + 1em);  /* var(--hns-c-status__icon--BlockSize) + var(--hns-c-status--Gap) */
  --hns-c-partner-modal__subscriber-data--RowGap: .36em;  /* 5px */

  --hns-c-partner-modal__subscriber-data-label--FontWeight: var(--hns-global--FontWeight-bold);
  --hns-c-partner-modal__subscriber-data-value--FontWeight: var(--hns-global--FontWeight-regular);
}



/**
 * Component
 * 1: reset
 * 2: center
 */

.hns-c-partner-modal {
  background-color: var(--hns-c-partner-modal--BackgroundColor);
  border: 0;  /* 1 */
  box-sizing: border-box;
  color: var(--hns-c-partner-modal--Color);
  padding-block: var(--hns-c-partner-modal--PaddingBlock);
  padding-inline: var(--hns-c-partner-modal--PaddingInline);
  margin: auto;  /* 2 */
}

@supports not (padding-block: var(--hns-c-partner-modal--PaddingBlock)) {
  .hns-c-partner-modal {
    padding: var(--hns-c-partner-modal--PaddingBlock) var(--hns-c-partner-modal--PaddingInline);
  }
}


/**
 * Content
 * 1: reset
 */

.hns-c-partner-modal > * {
  display: block;
  margin-block: 0;  /* 1 */
}

@supports not (margin-block: 0) {
  .hns-c-partner-modal > * {
    margin-bottom: 0;
    margin-top: 0;
  }
}



/**
 * Close button
 * 1: reset
 */

.hns-c-partner-modal > button {
  appearance: none;  /* 1 */
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  color: inherit;  /* 1 */
  cursor: pointer;
  font: inherit;  /* 1 */
  inset-block-start: var(--hns-c-partner-modal__button--InsetBlockStart);
  inset-inline-end: var(--hns-c-partner-modal__button--InsetInlineEnd);
  padding: 0;  /* 1 */
  position: absolute;
}

.hns-c-partner-modal > button:focus {
  outline: none;  /* 1 */
}

.hns-c-partner-modal > button:hover {
  --hns-c-icon-plus__circle--Fill: currentcolor;
  --hns-c-icon-plus__plus--Fill: white;
}

.hns-c-partner-modal > button svg {
  block-size: var(--hns-c-partner-modal__button--BlockSize);
  inline-size: auto;
  transform: rotateZ(45deg);
}

@supports not (inline-size: auto) {
  .hns-c-partner-modal > button {
    top: var(--hns-c-partner-modal__button--InsetBlockStart);
    right: var(--hns-c-partner-modal__button--InsetInlineEnd);
  }

  .hns-c-partner-modal > button svg {
    height: var(--hns-c-partner-modal__button--BlockSize);
    width: auto;
  }
}



/**
 * Logo
 */

.hns-c-partner-modal > svg {
  block-size: var(--hns-c-partner-modal__logo--BlockSize);
  margin-block-end: var(--hns-c-partner-modal__logo--MarginBlockEnd);
}

@supports not(inline-size: auto) {
  .hns-c-partner-modal > svg {
    height: var(--hns-c-partner-modal__logo--BlockSize);
    margin-bottom: var(--hns-c-partner-modal__logo--MarginBlockEnd);
  }
}



/**
 * Heading
 */

.hns-c-partner-modal > h1 {
  font-size: var(--hns-c-partner-modal__heading--FontSize);
  margin-block: var(--hns-c-partner-modal__heading--MarginBlock);
}

@supports not (margin-block: var(--hns-c-partner-modal__heading--MarginBlock)) {
  .hns-c-partner-modal > h1 {
    margin-bottom: var(--hns-c-partner-modal__heading--MarginBlock);
    margin-top: var(--hns-c-partner-modal__heading--MarginBlock);
  }
}



/**
 * Subheading
 */

.hns-c-partner-modal__subheading {
  display: block;
  font-weight: var(--hns-c-partner-modal__subheading--FontWeight);
}



/**
 * Backdrop
 * 1: can't use a custom property here. why?
 * 2: see https://github.com/GoogleChrome/dialog-polyfill#backdrop
 */

.hns-c-partner-modal::backdrop {
  /* background-color: var(--hns-c-partner-modal__backdrop--BackgroundColor); */
  background-color: #0d4580f2;  /* 1 */
}

.hns-c-partner-modal + .backdrop {  /* 2 */
  background-color: var(--hns-c-partner-modal__backdrop--BackgroundColor);
}



/**
 * Content
 */

.hns-c-partner-modal__content {
  border-block: 1px solid currentcolor;
  padding-block: var(--hns-c-partner-modal__content--PaddingBlock);
  margin-block: var(--hns-c-partner-modal__content--MarginBlockStart) var(--hns-c-partner-modal__content--MarginBlockEnd);
}

@supports not (border-block: 1px solid currentcolor) {
  .hns-c-partner-modal__content {
    border-bottom: 1px solid currentcolor;
    border-top: 1px solid currentcolor;
    margin-bottom: var(--hns-c-partner-modal__content--MarginBlockEnd);
    margin-top: var(--hns-c-partner-modal__content--MarginBlockStart);
    padding-bottom: var(--hns-c-partner-modal__content--PaddingBlock);
    padding-top: var(--hns-c-partner-modal__content--PaddingBlock);
  }
}



/**
 * Subscriber data
 * 1: reset
 */

.hns-c-partner-modal__subscriber dl {
  margin-block-end: 0;  /* 1 */
  margin-block-start: var(--hns-c-partner-modal__subscriber-data--RowGap);
  margin-inline-start: var(--hns-c-partner-modal__subscriber-data--MarginInlineStart);
}

.hns-c-partner-modal__subscriber dl > dt {
  font-weight: var(--hns-c-partner-modal__subscriber-data-label--FontWeight);
}

.hns-c-partner-modal__subscriber dl > :nth-child(n+2) {
  margin-block-start: var(--hns-c-partner-modal__subscriber-data--RowGap);
}

.hns-c-partner-modal__subscriber dl > dd {
  font-weight: var(--hns-c-partner-modal__subscriber-data-value--FontWeight);
  margin-inline-start: 0;  /* 1 */
}

@supports not (margin-inline-start: 0) {
  .hns-c-partner-modal__subscriber dl {
    margin-bottom: 0;  /* 1 */
    margin-left: var(--hns-c-partner-modal__subscriber-data--MarginInlineStart);
    margin-top: var(--hns-c-partner-modal__subscriber-data--RowGap);
  }

  .hns-c-partner-modal__subscriber dl > :nth-child(n+2) {
    margin-top: var(--hns-c-partner-modal__subscriber-data--RowGap);
  }

  .hns-c-partner-modal__subscriber dl > dd {
    margin-left: 0;  /* 1 */
  }
}

/** ***********************************************************************************
 * Atoms / Typography / Inline elements / Anchor
 *********************************************************************************** */

:root {
  /**
   * Link
   */

  --hns-c-page__link--FontSize: var(--hns-global--FontWeight-bold);
}



/**
 * Component
 */

a {
  color: currentcolor;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

p a {
  font-weight: var(--hns-c-page__link--FontSize);
}


a[href^="geo:"] {
  text-decoration: none;
  pointer-events: none;
}

@media (hover: none) and (pointer: coarse) {
  a[href^="geo:"] {
    text-decoration: inherit;
    pointer-events: all;
  }
}

/** ***************************************************************************
 * Organisms / Account / Side column
 *************************************************************************** */

:root {
  --hns-c-account-aside--AccentColor: var(--hns-global--palette-dark--100);
  --hns-c-account-aside--BackgroundColor: var(--hns-global--palette-blue--80);
  --hns-c-account-aside--Color: var(--hns-global--palette-white);
  --hns-c-account-aside--TransitionDuration: var(--hns-global--Duration--sm);
  --hns-c-account-aside--PaddingBlockEnd: 2.75rem;  /* 43px */

  --hns-c-account-aside--tn--PaddingBlockStart: 2.25rem;  /* 36px */
  --hns-c-account-aside--lg--PaddingBlockStart: var(--hns-c-account-aside--PaddingBlockEnd);
  --hns-c-account-aside--PaddingBlockStart: var(--hns-c-account-aside--tn--PaddingBlockStart);

  --hns-c-account-aside--tn--PaddingInlineStart: calc(var(--hns-global--content-grid--column-width) + var(--hns-global--content-grid--gutter-width));
  --hns-c-account-aside--lg--PaddingInlineStart: 4.875rem;  /* 78px */
  --hns-c-account-aside--PaddingInlineStart: var(--hns-c-account-aside--tn--PaddingInlineStart);

  --hns-c-account-aside--tn--TemplateRows: auto;
  --hns-c-account-aside--lg--TemplateRows: 1fr max-content 1fr;
  --hns-c-account-aside--lg-lg--TemplateRows: 20.25rem max-content 1fr;
  --hns-c-account-aside--TemplateRows: var(--hns-c-account-aside--tn--TemplateRows);

  --hns-c-account-aside--tn--ClipPath: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  --hns-c-account-aside--lg--ClipPath: polygon(
    -100vmax 0,
    100% 0,
    calc(
      100%
      - var(--hns-global--content-grid--column-width)
      - var(--hns-global--content-grid--gutter-width)
    ) 100%,
    -100vmax 100%
  );
  --hns-c-account-aside--ClipPath: var(--hns-c-account-aside--tn--ClipPath);


  /**
   * Logo
   */

  --hns-c-account-aside__logo--tn--BlockSize: 5.375rem;  /* 86px */
  --hns-c-account-aside__logo--lg--BlockSize: 8rem;
  --hns-c-account-aside__logo--xl--BlockSize: 9.375rem;  /* 150px */
  --hns-c-account-aside__logo--BlockSize: var(--hns-c-account-aside__logo--tn--BlockSize);

  --hns-c-account-aside__logo--tn--Row: 1 / span 1;
  --hns-c-account-aside__logo--lg--Row: 2 / span 1;
  --hns-c-account-aside__logo--Row: var(--hns-c-account-aside__logo--tn--Row);
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  :root {
    --hns-c-account-aside--ClipPath: var(--hns-c-account-aside--lg--ClipPath);
    --hns-c-account-aside--PaddingBlockStart: var(--hns-c-account-aside--lg--PaddingBlockStart);
    --hns-c-account-aside--PaddingInlineStart: var(--hns-c-account-aside--lg--PaddingInlineStart);
    --hns-c-account-aside--TemplateRows: var(--hns-c-account-aside--lg--TemplateRows);
    --hns-c-account-aside__logo--BlockSize: var(--hns-c-account-aside__logo--lg--BlockSize);
    --hns-c-account-aside__logo--Row: var(--hns-c-account-aside__logo--lg--Row);
  }

  /**
   * Large / Large
   */

  @media all and (min-height: 50rem) {
    :root {
      --hns-c-account-aside--TemplateRows: var(--hns-c-account-aside--lg-lg--TemplateRows);
    }
  }
}

/**
 * Extralarge
 */

@media all and (min-width: 80rem) {
  :root {
    --hns-c-account-aside__logo--BlockSize: var(--hns-c-account-aside__logo--xl--BlockSize);
  }
}



/**
 * Component
 * 1: extend background (it is cut by clip-path)
 */

.hns-c-account-aside {
  accent-color: var(--hns-c-account-aside--AccentColor);
  background-color: var(--hns-c-account-aside--BackgroundColor);
  box-shadow: 0 0 0 100vmax var(--hns-c-account-aside--BackgroundColor);  /* 1 */
  clip-path: var(--hns-c-account-aside--ClipPath);
  color: var(--hns-c-account-aside--Color);
  display: grid;
  grid-template-rows: var(--hns-c-account-aside--TemplateRows);
  padding-block: var(--hns-c-account-aside--PaddingBlockStart) var(--hns-c-account-aside--PaddingBlockEnd);
  padding-inline-start: var(--hns-c-account-aside--PaddingInlineStart);
  transition: clip-path var(--hns-c-account-aside--TransitionDuration) linear;
}

@supports not (padding-block: var(--hns-c-account-aside--PaddingBlockStart) var(--hns-c-account-aside--PaddingBlockEnd)) {
  .hns-c-account-aside {
    padding:
      var(--hns-c-account-aside--PaddingBlockStart)
      auto
      var(--hns-c-account-aside--PaddingBlockEnd)
      var(--hns-c-account-aside--PaddingInlineStart);
  }
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-account-aside {
    position: fixed;
    inset: 0 auto 0 50%;
    transform: translateX(calc(-1 * var(--hns-global--content-grid--content-width) / 2));
    inline-size: calc(4 * var(--hns-global--content-grid--column-width) + 2 * var(--hns-global--content-grid--gutter-width));
  }

  @supports not (inset: 0 auto 0 50%) {
    .hns-c-account-aside {
      top: 0;
      bottom: 0;
      left: 50%;
    }
  }

  @supports not (width: calc(4 * var(--hns-global--content-grid--column-width) + 2 * var(--hns-global--content-grid--gutter-width))) {
    .hns-c-account-aside {
      width: calc(4 * var(--hns-global--content-grid--column-width) + 2 * var(--hns-global--content-grid--gutter-width));
    }
  }
}



/**
 * Logo
 */

.hns-c-account-aside svg {
  --color-body: currentcolor;
  --color-shadow: var(--hns-c-account-aside--AccentColor);
  --color-valsabbia: currentcolor;
  grid-row: var(--hns-c-account-aside__logo--Row);
  block-size: var(--hns-c-account-aside__logo--BlockSize);
}

@supports not (block-size: var(--hns-c-account-aside__logo--BlockSize)) {
  .hns-c-account-aside svg {
    height: var(--hns-c-account-aside__logo--BlockSize);
  }
}

/** ***************************************************************************
 * Molecules / Site navigation switch
 *************************************************************************** */

.hns-c-nav-switch {
  --hs-c-nav-switch--RowGap: .3em;
}



/**
 * Component
 */

.hns-c-nav-switch {
  align-items: stretch;
  display: grid;
  grid-row-gap: var(--hs-c-nav-switch--RowGap);
  grid:
    "icon" 1.5em
    "label" auto
  / 1.5em;
  justify-items: stretch;
  line-height: 1;
  overflow-wrap: break-word;
  text-transform: uppercase;
  word-break: break-all;
}



/**
 * Children
 */

.hns-c-nav-switch > * {
  margin: 0;
  padding: 0;
  width: 100%;
}

/** ***************************************************************************
 * Atoms / Reserved area / Navigation switch
 *************************************************************************** */

.hns-c-reserved-nav-switch {
  --hns-c-reserved-nav-switch--flag: 0;

  --hns-c-reserved-nav-switch--BackgroundColor: var(--hns-global--palette-blue);
  --hns-c-reserved-nav-switch--BlockSize: 5em;  /* 70px */
  --hns-c-reserved-nav-switch--BorderColor: var(--hns-global--palette-blue--10);
  --hns-c-reserved-nav-switch--Color: var(--hns-global--palette-white);
  --hns-c-reserved-nav-switch--ColumnGap: 1rem;
  --hns-c-reserved-nav-switch--Font: var(--hns-global--FontWeight-regular) var(--hns-global--FontSize-label--xs) var(--hns-global--FontFamily-normal);
  --hns-c-reserved-nav-switch--Grid:
    "icon action"
    "icon label"
  / auto 1fr;
  --hns-c-reserved-nav-switch--TransitionDuration: var(--hns-global--Duration--xs);

  --hns-c-reserved-nav-switch--PaddingInline: calc(var(--hns-global--content-grid--column-width) + var(--hns-global--content-grid--gutter-width));


  /**
   * Icon
   */

  --hns-c-reserved-nav-switch__icon--BlockSize: 2.29em;  /* 32px */


  /**
   * Action
   */

  --hns-c-reserved-nav-switch__action--FontSize: var(--hns-global--FontSize-label--sm);
  --hns-c-reserved-nav-switch__action--FontWeight: var(--hns-global--FontWeight-bold);


  /**
   * Label
   */

  --hns-c-reserved-nav-switch__label--MarginBlockStart: -0.143em;
}



/**
 * Component
 * 1: extend background
 */

.hns-c-reserved-nav-switch {
  background-color: var(--hns-c-reserved-nav-switch--BackgroundColor);
  block-size: var(--hns-c-reserved-nav-switch--BlockSize);
  border-block-start: 2px solid var(--hns-c-reserved-nav-switch--BorderColor);
  box-shadow: 0 0 0 100vmax var(--hns-c-reserved-nav-switch--BackgroundColor);  /* 1 */
  clip-path: inset(0 -100vmax);  /* 1 */
  color: var(--hns-c-reserved-nav-switch--Color);
  display: grid;
  font: var(--hns-c-reserved-nav-switch--Font);
  grid-column-gap: var(--hns-c-reserved-nav-switch--ColumnGap);
  grid: var(--hns-c-reserved-nav-switch--Grid);
  padding-inline: var(--hns-c-reserved-nav-switch--PaddingInline);
}

@supports not (padding-block: var(--hns-c-reserved-nav-switch--PaddingBlock)) {
  .hns-c-reserved-nav-switch {
    height: var(--hns-c-reserved-nav-switch--BlockSize);
    padding-left: var(--hns-c-reserved-nav-switch--PaddingInline);
    padding-right: var(--hns-c-reserved-nav-switch--PaddingInline);
  }
}



/**
 * Icon
 */

.hns-c-reserved-nav-switch > svg {
  align-self: center;
  block-size: var(--hns-c-reserved-nav-switch__icon--BlockSize);
  grid-area: icon;
  transform: rotateZ(calc(var(--hns-c-reserved-nav-switch--flag) * 45deg));
  transition: transform var(--hns-c-reserved-nav-switch--TransitionDuration) ease-in;
}

@supports not (block-size: var(--hns-c-reserved-nav-switch__icon--BlockSize)) {
  .hns-c-reserved-nav-switch > svg {
    height: var(--hns-c-reserved-nav-switch__icon--BlockSize);
  }
}



/**
 * Action
 */

.hns-c-reserved-nav-switch__action {
  display: contents;
  font-size: var(--hns-c-reserved-nav-switch__action--FontSize);
  font-weight: var(--hns-c-reserved-nav-switch__action--FontSize);
}

.hns-c-reserved-nav-switch__action > * {
  align-self: end;
  grid-area: action;
  transition: transform var(--hns-c-reserved-nav-switch--TransitionDuration) ease-in;
}

.hns-c-reserved-nav-switch__action > :first-child {
  transform: scaleY(calc(1 - var(--hns-c-reserved-nav-switch--flag)));
}

.hns-c-reserved-nav-switch__action > :last-child {
  transform: scaleY(var(--hns-c-reserved-nav-switch--flag));
}



/**
 * Label
 */

.hns-c-reserved-nav-switch__label {
  margin-block-start: var(--hns-c-reserved-nav-switch__label--MarginBlockStart);
}

@supports not (margin-block-start: var(--hns-c-reserved-nav-switch__label--MarginBlockStart)) {
  .hns-c-reserved-nav-switch__label {
    margin-top: var(--hns-c-reserved-nav-switch__label--MarginBlockStart);
  }
}
